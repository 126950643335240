<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0">Divisions</h4>
        </div>
        <!-- <div class="align-items-center gap-2 ml-auto">
            <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Serach" v-model="meta.search" @keypress.enter="search()" />
        </div> -->
    </div>
    <div class="row g-3 mb-2">
        <div class="col-xl-12" style="height: fit-content;">
            <form class="card card-one" @submit.prevent="submitForm()">
                <div class="card-header">
                    <h6 class="card-title" v-if="status">New Division</h6>
                    <h6 class="card-title" v-else>Update Division</h6>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Division</label><span class="text-danger"> *</span>
                            <input type="text" class="form-control border-0 border-bottom" placeholder="Division" :class="{'is-invalid': errors.division}" v-model="division.division" ref="division" />
                            <span v-if="errors.division" class="invalid-feedback">{{ errors.division[0] }}</span>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">E-Mail</label><span class="text-danger"> *</span>
                            <input type="email" class="form-control border-0 border-bottom" placeholder="Enter official email." :class="{'is-invalid': errors?.email}" v-model="division.email" ref="email" />
                            <span v-if="errors?.email" class="invalid-feedback">{{ errors?.email[0] }}</span>
                        </div>
                        <div class="col-md-12">
                            <label class="form-label">Address</label><span class="text-danger"> *</span>
                            <textarea class="form-control border-0 border-bottom" cols="30" rows="2" placeholder="Enter division address." :class="{'is-invalid': errors?.email}" v-model="division.address"></textarea>                            
                            <span v-if="errors?.email" class="invalid-feedback">{{ errors?.address[0] }}</span>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-end">
                    <button type="button" class="btn btn-danger me-2" @click="discard()"><i class="ri-prohibited-line me-1"></i>Discard</button>
                    <button type="submit" class="btn btn-primary">
                        <span v-if="status"> <i class="ri-save-line me-1"></i> Submit</span>
                        <span v-else> <i class="ri-save-line me-1"></i> Update </span>
                    </button>
                </div>
            </form>
        </div>
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-header">
                    <h6 class="card-title">Divisions</h6>                   
                </div>
                <div class="card-body">
                    <input type="text" class="form-control mb-2" id="search" placeholder="Search" v-model="meta.search" @keypress.enter="search()">
                    <div class="table-responsive" style="overflow-y:scroll; height: 60vh;">
                        <table class="table text-nowrap table-bordered table-hover table-bottom-border  mb-0">
                            <thead>
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center">Sl No.</th>
                                    <th @click="sort('division')">
                                        Division
                                        <span>
                                            <i v-if="meta.keyword=='division' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword=='division' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th class="text-center">E-Mail</th>
                                    <th class="text-center">Address</th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="divisions.length==0">
                                        <td colspan="6" class="text-center">No records found</td>
                                    </tr>
                                <tr v-for="division, key in divisions" :key="key">
                                    <td class="text-center">{{ meta.from + key }}</td>
                                    <td>{{ division.division }}</td>
                                    <td>{{ division.email }}</td>
                                    <td>{{ division.address }}</td>
                                    <td class="text-center">
                                        <div class="form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" :id="'division' + division.division_id" :checked="division.status" :value="division.status" @change="deleteDivision(division)" />
                                            <label class="custom-control-label" :for="'division' + division.division_id"></label>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <nav>
                                            <a href="javascript:void(0)" class="text-primary me-2" v-if="division.status" @click="editDivision(division)"><i class="ri-edit-line fs-18 lh-1"></i></a>
                                        </nav>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: {
            Pagination,
        },
        data() {
            return {
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "division_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                    trashed: false,
                },
                division: {
                    division_id: "",
                    division: "",
                    email: '',
                    address: ''
                },
                divisions: [],
                status: true,
                errors: [],
            };
        },
        mounted(){
            this.$refs.division.focus();
            this.index();
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },
            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateDivisions", data: vm.meta })
                    .then((response) => {
                        vm.divisions = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.to = response.data.meta.to;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "addDivision", data: vm.division })
                    .then((response) => {
                        loader.hide();
                        vm.$store.dispatch("success", "Division Added successfully");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            update() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "updateDivision", data: vm.division })
                    .then((response) => {
                        loader.hide();
                        vm.$store.dispatch("success", "Division is successfully updated");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            editDivision(division) {
                let vm = this;
                vm.$refs.division.focus();
                vm.errors = [];
                vm.status = false;
                vm.division = division;
            },

            deleteDivision(division) {
                let vm = this;
                let loader = vm.$loading.show();
                division.status = division.status == 1 ? 0 : 1;
                vm.$store
                    .dispatch("post", { uri: "deleteDivision", data: division })
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", response.data.message);
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            onPageChange(page) {
                let vm = this;
                vm.meta.page = page;
                vm.index();
            },
            sort(field) {
                let vm = this;
                vm.meta.keyword = field;
                vm.meta.order_by = vm.meta.order_by == "asc" ? "desc" : "asc";
                vm.index();
            },
            discard() {
                let vm = this;
                vm.division.division = "";
                vm.division.email = "";
                vm.division.address = "";
                vm.$refs.division.focus();
                vm.errors = [];
                vm.status = true;
                vm.index();
            },
            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },
        },
    };
</script>
<style scoped>
 input:focus,
    select:focus {
        outline: none;
        box-shadow: 0 0 0 0 transparent;
    }
</style>