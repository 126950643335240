<template>
    <div class="d-md-flex align-items-center justify-content-between mb-3">
        
        <div>
            <div class="d-flex align-items-center gap-2 mb-2">
                <a id="menuSidebar" href="#" class="menu-link me-3 me-lg-4 fs-24"><i class="ri-menu-2-fill"></i></a>
                <a href="javascript:void(0)" class="text-dark" ><i class="ri-arrow-left-line fs-24"></i></a>
                <div class="avatar"><span class="avatar-initial bg-light-primary ">KC</span></div>
                <div class="review-body">
                    <h6 class="text-dark fs-20 mb-0">Kala Kacha</h6>
                    <div class="d-flex align-items-center gap-1 text-dark">
                        <small><i class="ri-map-pin-fill me-1"></i>Hebbal, Bengaluru</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex gap-3 mt-3 mt-md-0">
            <div class="form-check form-switch my-auto">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked/>
                <label class="form-check-label" for="flexSwitchCheckChecked">Opposite Gang</label>
            </div>
            <button type="button" class="btn btn-outline-primary d-flex align-items-center gap-2"><i class="ri-add-line fs-18 lh-1"></i>Create New Gang</button>
        </div>
    </div>


    <div class=" mb-3">
       
          <h4 class="main-title mb-0">Opposite Gang</h4>
      </div>
    <div class="row g-3 mb-2">
        <div class="col-sm-4">
          <div class="card card-one border-primary">
            <div class="card-body p-3">
              <div class="d-flex align-items-center gap-2 mb-2">
                <div class="avatar"><span class="avatar-initial bg-primary">KC</span></div>
                <div class="review-body">
                    <h6 class="text-dark mb-0">Kala Kacha</h6>
                    <div class="d-flex align-items-center gap-1 text-primary">
                        <small class="text-dark">Bengaluru East</small>
                    </div>
                </div>
                <div class="mutual-badge rounded-pill badge bg-light-blue">
                        <ul>
                        <li><a href="" class="avatar"><img src="assets/img/img15.jpg" alt=""></a></li>
                        <li><a href="" class="avatar"><img src="assets/img/img16.jpg" alt=""></a></li>
                        <li><a href="" class="avatar"><img src="assets/img/img16.jpg" alt=""></a></li>
                        <li><a href="" class="avatar"><img src="assets/img/img12.jpg" alt=""></a></li>
                        </ul>
                        <label>48 Members</label>
                    </div>

            </div>
            </div><!-- card-body -->
          </div><!-- card -->
        </div>
        <div class="col-sm-4">
          <div class="card card-one">
            <div class="card-body p-3">
              <div class="d-flex align-items-center gap-2 mb-2">
                <div class="avatar"><span class="avatar-initial bg-secondary">BT</span></div>
                <div class="review-body">
                    <h6 class="text-dark mb-0">BTR 23</h6>
                    <div class="d-flex align-items-center gap-1 text-primary">
                        <small class="text-dark">Bengaluru East</small>
                    </div>
                </div>
                <div class="mutual-badge badge bg-light-blue rounded-pill">
                        <ul>
                        <li><a href="" class="avatar"><img src="assets/img/img15.jpg" alt=""></a></li>
                        <li><a href="" class="avatar"><img src="assets/img/img16.jpg" alt=""></a></li>
                        <li><a href="" class="avatar"><img src="assets/img/img16.jpg" alt=""></a></li>
                        <li><a href="" class="avatar"><img src="assets/img/img12.jpg" alt=""></a></li>
                        </ul>
                        <label>48 Members</label>
                    </div>

            </div>
            </div><!-- card-body -->
          </div><!-- card -->
        </div>
     
      </div>
    <div class="row g-3">
        <div class="col-xl-5">
            <div class="card card-one">
                <div class="card-body">
                    <div class="d-sm-flex p-2 p-sm-4 p-md-2 p-xl-2 mb-3">
                        <div id="cAvatar" class="me-4 mb-3 mb-sm-0">
                            <div class="avatar"><img class="leader-img" src="assets/img/img12.jpg" alt="" /></div>
                        </div>
                        <div class="flex-fill">
                            <span class="badge bg-light-secondary rounded-pill ">GANG LEADER</span>
                            <h5 id="cName" class="mb-0 fs-20 text-dark fw-semibold">Narayana Swamy</h5>
                            <small>JCB Narayan, BN Hanumantapa</small>
                        </div>
                    </div>
                    <div class="row g-3 text-center border-top">
                        <div class="col-sm-4 mt-2 border-right">
                            <h3 class="mb-0 fs-18 text-dark">28</h3>
                            <small class="card-title text-uppercase text-secondary mb-2">Field Cases</small>
                        </div>
                        <!-- col -->
                        <div class="col-sm-4 mt-2 border-right">
                            <h3 class="mb-0 fs-18 text-dark">18</h3>
                            <small class="card-title text-uppercase text-secondary mb-2">Court Cases</small>
                        </div>
                        <div class="col-sm-4 mt-2">
                            <h3 class="mb-0 fs-18 text-dark">3</h3>
                            <small class="card-title text-uppercase text-secondary mb-2">Police Stations</small>
                        </div>
                        <div class="col-sm-12">
                            <button type="button" class="btn m-auto btn-outline-primary d-flex align-items-center gap-2"><i class="ri-eye-line fs-18 lh-1"></i>View Profile</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-7">
            <div class="card card-one py-4">
                <div class="card-body">
                    <ul class="nav nav-tabs card-header-tabs mb-0 justify-content-center" data-bs-tabs="tabs">
                        <li class="nav-item">
                            <a class="nav-link text-dark active" aria-current="true" data-bs-toggle="tab" href="#cases">Cases</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-dark" data-bs-toggle="tab" href="#process">Process</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-dark" data-bs-toggle="tab" href="#goonda">Goonda Act / Externment</a>
                        </li>
                    </ul>
                    <form class="card-body tab-content">
                        <div class="tab-pane active" id="cases">
                            <div class="row mt-2 g-3 text-center border-top1 text-dark">
                                <div class="col mt-2 border-right">
                                    <h3 class="mb-0 text-center">02</h3>
                                    <label class="card-title fw-semibold mb-2">302 IPC</label>
                                </div>
                                <div class="col mt-2 border-right">
                                    <h3 class="mb-0 text-center">01</h3>
                                    <label class="card-title fw-semibold mb-2">307 IPC</label>
                                </div>
                                <div class="col mt-2 border-right">
                                    <h3 class="mb-0 text-center">05</h3>
                                    <label class="card-title fw-semibold mb-2">149 IPC</label>
                                </div>
                                <div class="col mt-2 border-right">
                                    <h3 class="mb-0 text-center">23</h3>
                                    <label class="card-title fw-semibold mb-2">420 IPC</label>
                                </div>
                                <!-- col -->
                                <div class="col mt-2 border-right">
                                    <h3 class="mb-0 text-center">12</h3>
                                    <label class="card-title fw-semibold mb-2">Robbery</label>
                                </div>
                                <div class="col mt-2">
                                    <h3 class="mb-0 text-center">43</h3>
                                    <label class="card-title fw-semibold mb-2">Total</label>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="process">
                            <div class="row mt-2 g-3 text-center border-top1 text-dark">
                                <div class="col mt-2 border-right">
                                    <h3 class="mb-0 text-center">02</h3>
                                    <label class="card-title fw-semibold mb-2">Notices</label>
                                </div>
                                <div class="col mt-2 border-right">
                                    <h3 class="mb-0 text-center">01</h3>
                                    <label class="card-title fw-semibold mb-2">Warrants</label>
                                </div>
                                <div class="col mt-2 border-right">
                                    <h3 class="mb-0 text-center">05</h3>
                                    <label class="card-title fw-semibold mb-2">Summons</label>
                                </div>
                                <div class="col mt-2 border-right">
                                    <h3 class="mb-0 text-center">23</h3>
                                    <label class="card-title fw-semibold mb-2">Proclamation</label>
                                </div>
                                <div class="col mt-2">
                                    <h3 class="mb-0 text-center">43</h3>
                                    <label class="card-title fw-semibold mb-2">Total</label>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="goonda">
                            <div class="row mt-2 g-3 text-center border-top1">
                                <div class="col mt-2 border-right">
                                    <h3 class="mb-0 text-center">02</h3>
                                    <label class="card-title fw-semibold mb-2">Goonda Act</label>
                                </div>
                                <div class="col mt-2 border-right">
                                    <h3 class="mb-0 text-center">01</h3>
                                    <label class="card-title fw-semibold mb-2">Externment</label>
                                </div>
                                <div class="col mt-2">
                                    <h3 class="mb-0 text-center">43</h3>
                                    <label class="card-title fw-semibold mb-2">Total</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-xl-12 my-4">
            <div class="border-design border-bottom-0 mb-1">
                <p class="card-text text-center">
                    <span class="badge rounded-pill bg-light-white rounded text-dark fs-14 text-center">56 Members</span>
                </p>
            </div>
            <div class="row gap-2 row-cols-1 row-cols-md-auto d-flex justify-content-between justify-content-md-evenly">
                <div class="col mb-2">
                    <div class="card card-people">
                        <div class="card-body">
                            <a href=""><img class="multiple_img img-fluid" src="assets/img/img16.jpg" alt="" />
                                <span class="badge bg-danger rounded badge1">Dead</span>
                            </a>
                            <h6 class="mt-3 text-center"><a href="">Sanjay Singh</a></h6>
                            <div class="product-review-footer mt-auto" style="justify-content: center;">
                                <a href="">47y</a>
                                <a href=""> 28 cases</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="card card-people">
                        <div class="card-body">
                            <a href=""><img class="multiple_img img-fluid" src="assets/img/img16.jpg" alt="" /></a>
                            <h6 class="mt-3 text-center"><a href="">Sanjay Singh</a></h6>
                            <div class="product-review-footer mt-auto" style="justify-content: center;">
                                <a href="">47y</a>
                                <a href=""> 28 cases</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="card card-people">
                        <div class="card-body">
                            <a href=""><img class="multiple_img img-fluid" src="assets/img/img16.jpg" alt="" /></a>
                            <h6 class="mt-3 text-center"><a href="">Sanjay Singh</a></h6>
                            <div class="product-review-footer mt-auto" style="justify-content: center;">
                                <a href="">47y</a>
                                <a href=""> 28 cases</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="card card-people">
                        <div class="card-body">
                            <a href=""><img class="multiple_img img-fluid" src="assets/img/img16.jpg" alt="" /></a>
                            <h6 class="mt-3 text-center"><a href="">Sanjay Singh</a></h6>
                            <div class="product-review-footer mt-auto" style="justify-content: center;">
                                <a href="">47y</a>
                                <a href=""> 28 cases</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="card card-people">
                        <div class="card-body">
                            <a href=""><img class="multiple_img img-fluid" src="assets/img/img16.jpg" alt="" /></a>
                            <h6 class="mt-3 text-center"><a href="">Sanjay Singh</a></h6>
                            <div class="product-review-footer mt-auto" style="justify-content: center;">
                                <a href="">47y</a>
                                <a href=""> 28 cases</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="card card-people">
                        <div class="card-body">
                            <a href=""><img class="multiple_img img-fluid" src="assets/img/img16.jpg" alt="" /></a>
                            <h6 class="mt-3 text-center"><a href="">Sanjay Singh</a></h6>
                            <div class="product-review-footer mt-auto" style="justify-content: center;">
                                <a href="">47y</a>
                                <a href=""> 28 cases</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="card card-people">
                        <div class="card-body">
                            <a href=""><img class="multiple_img img-fluid" src="assets/img/img16.jpg" alt="" /></a>
                            <h6 class="mt-3 text-center"><a href="">Sanjay Singh</a></h6>
                            <div class="product-review-footer mt-auto" style="justify-content: center;">
                                <a href="">47y</a>
                                <a href=""> 28 cases</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="card card-people">
                        <div class="card-body">
                            <a href=""><img class="multiple_img img-fluid" src="assets/img/img16.jpg" alt="" /></a>
                            <h6 class="mt-3 text-center"><a href="">Sanjay Singh</a></h6>
                            <div class="product-review-footer mt-auto" style="justify-content: center;">
                                <a href="">47y</a>
                                <a href=""> 28 cases</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="card card-people">
                        <div class="card-body">
                            <a href=""><img class="multiple_img img-fluid" src="assets/img/img16.jpg" alt="" /></a>
                            <h6 class="mt-3 text-center"><a href="">Sanjay Singh</a></h6>
                            <div class="product-review-footer mt-auto" style="justify-content: center;">
                                <a href="">47y</a>
                                <a href=""> 28 cases</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col mb-2">
                    <div class="card card-people">
                        <div class="card-body">
                            <a href=""><img class="multiple_img img-fluid" src="assets/img/img16.jpg" alt="" /></a>
                            <h6 class="mt-3 text-center"><a href="">Sanjay Singh</a></h6>
                            <div class="product-review-footer mt-auto" style="justify-content: center;">
                                <a href="">47y</a>
                                <a href=""> 28 cases</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
