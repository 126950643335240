<template>
    <div class="d-md-flex align-items-center justify-content-between1 gap-4 mt-2 mb-4">
        <h2 class="main-title fs-28 mb-0">All Users</h2>
        <div class="align-items-center gap-2 ml-auto">
            <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Search"
                v-model="meta.search" @keypress.enter="search()" />
        </div>
        <button v-can="'user.create'" type="button" class="mt-auto btn btn-outline-primary d-flex align-items-center gap-2"
            @click="addUser()"><i class="ri-add-line fs-18 lh-1"></i>Add New User
        </button>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-body">
                    <div class="table-responsive" style="overflow-y:scroll; height: 70vh;">
                        <table class="table text-nowrap table-hover table-striped table-bottom-border mb-0">
                            <thead>
                                <tr style="background-color: #f5f5f5;" class="text-center">
                                    <th class="text-center">Sl No.</th>
                                    <th @click="sort('name')">Police Station /<br> Division / <br> Sub Division / Name
                                        <span>
                                            <i v-if="meta.keyword == 'name' && meta.order_by == 'asc'" class="ri-sort-alphabet-asc"></i>
                                            <i v-else-if="meta.keyword == 'name' && meta.order_by == 'desc'"
                                                class="ri-sort-alphabet-desc"></i>
                                            <i v-else class="ri-arrow-up-down-line"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('role_id')">Role
                                        <span>
                                            <i v-if="meta.keyword == 'role_id' && meta.order_by == 'asc'" class="ri-sort-alphabet-asc"></i>
                                            <i v-else-if="meta.keyword == 'role_id' && meta.order_by == 'desc'"
                                                class="ri-sort-alphabet-desc"></i>
                                            <i v-else class="ri-arrow-up-down-line"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('email')">E-Mail
                                        <span>
                                            <i v-if="meta.keyword == 'email' && meta.order_by == 'asc'" class="ri-sort-alphabet-asc"></i>
                                            <i v-else-if="meta.keyword == 'email' && meta.order_by == 'desc'"
                                                class="ri-sort-alphabet-desc"></i>
                                            <i v-else class="ri-arrow-up-down-line"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('mobile_no')">Mobile
                                        <span>
                                            <i v-if="meta.keyword == 'mobile_no' && meta.order_by == 'asc'" class="ri-sort-number-asc"></i>
                                            <i v-else-if="meta.keyword == 'mobile_no' && meta.order_by == 'desc'"
                                                class="ri-sort-number-desc"></i>
                                            <i v-else class="ri-arrow-up-down-line"></i>
                                        </span>
                                    </th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user, key in user_list" :key="key">
                                    <td class="text-center">{{ meta.from + key }}</td>
                                    <!-- <td> {{ user.name }} </td> -->
                                    <td> 
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                                    :searchWords="keywords" :autoEscape="true"
                                                    :textToHighlight="user.name" />
                                    </td>
                                    <td class="text-center">{{ user.role?.role }}</td>
                                    <td>{{ user.email }}</td>
                                    <td class="text-center">{{ user.mobile_no }}</td>
                                    <td class="text-center">
                                        <nav>
                                            <!-- <router-link :to="'/rowdy_details/' + user.user_id"
                                                class="text-success me-2"><i
                                                    class="ri-eye-line fs-18 lh-1"></i></router-link> -->
                                            <a v-can="'user.update'" href="javascript:void(0)" @click="editUser(user.user_id)"
                                                class="text-primary me-2"><i class="ri-edit-line fs-18 lh-1" title="Edit User"></i></a>
                                            <!-- <a v-can="'user.delete'" href="javascript:void(0)" @click="deleteUser(user.user_id)"
                                                class="text-danger"><i class="ri-delete-bin-6-line fs-18 lh-1" title="Delete User"></i></a> -->
                                        </nav>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                        @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import Highlighter from 'vue-highlight-words';
export default {
    components: {
        Pagination, Highlighter
    },
    name: "RowdiesCreate",
    data() {
        return {
            user_list: [],
            meta: {
                search: '',
                order_by: "asc",
                keyword: "user_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
            },
        }
    },
    mounted() {
        this.getUsers();
    },
    computed: {
        keywords() {
            return this.meta.search.split(' ')
        }
    },
    methods: {
        getUsers() {
            let vm = this;
            let uri = { uri: "paginateUsers", data: vm.meta };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.user_list = response.data.data
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        editUser(user_id) {
            this.$router.push("/user_update/" + user_id);
        },

        addUser() {
            this.$router.push('/user_create');
        },

        deleteUser(user_id) {
            if(!confirm('User will be deleted permanently.')) return;
            let vm = this;
            let uri = { uri: "deleteUser", data: { user_id: user_id } };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.$store.dispatch('success', response.data.message);
                    vm.getUsers();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },


        onPageChange(page) {
            this.meta.page = page;
            this.getUsers();
        },

        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.getUsers();
        },
        search() {
            this.meta.page = 1;
            this.getUsers();
        }
    }

}
</script>
<style scoped>
.card-one {
    border-width: 0px;
}

@media only screen and (max-width: 767px) {
    .gap-2 {
        margin-bottom: 0.5rem;
    }
}
</style>
