<template>
    <div class="sidebar">
        <div class="sidebar-header gap-3">
            <a href="javascript:void(0)" class="sidebar-logo"><img src="assets/img/log11.png" class="img" width="40"
                    height="40" /></a>
            <div>
                <a class="text-dark header-logo mb-0 mt-3">Rowdies Gangs</a>
                <p class="fs-12 font-gray">Bengaluru City Police</p>
            </div>
        </div>
        <div id="sidebarMenu" class="sidebar-body ps">
            <div class="nav-group" :class="{'show':gang_menu}" @click="gangMenu()" ref="gang_id">
                <a href="javascript:void(0)" class="nav-label"><i class="ri-group-line me-2 menu_icon"></i> Gangs</a>
                <ul class="nav nav-sidebar" @click.stop="stopMenu()">
                    <li class="nav-item" v-can="'gang.create'">
                        <router-link to="/create_gang" class="nav-link"><i class="ri-add-box-line"></i> <span>New
                                Gang</span></router-link>
                    </li>
                    <li class="nav-item" v-can="'gang.gang_list'">
                        <router-link to="/gang_list" class="nav-link"><i class="ri-file-list-3-line"></i> <span>All
                                Gangs</span></router-link>
                    </li>
                    <!-- <li class="nav-item">
                        <router-link to="/opposite_gang" class="nav-link"><i class="ri-file-list-3-line"></i> <span>Testing</span></router-link>
                    </li> -->
                </ul>
            </div>
            <div class="nav-group" :class="{'show':rowdy_menu}" @click="rowdyMenu()" ref="rowdy_id">
                <a href="javascript:void(0)" class="nav-label"><i class="ri-user-6-line me-2 menu_icon"></i>
                    Rowdy Sheeter</a>
                <ul class="nav nav-sidebar" ref="rowdy_sheeter" @click.stop="stopMenu()">
                    <li class="nav-item" v-can="'rowdy.create'">
                        <router-link to="/create_rowdy" class="nav-link"><i class="ri-add-box-line"></i> <span>New
                                Rowdy</span></router-link>
                    </li>
                    <li class="nav-item" v-can="'rowdy.rowdy_list'">
                        <router-link to="/all_rowdy_list" class="nav-link"><i class="ri-group-line"></i> <span>All Rowdy
                                Sheeter</span></router-link>
                    </li>
                    <li class="nav-item" v-can="'rowdy.search'">
                        <router-link to="/search_rowdy" class="nav-link"><i class="ri-user-search-line"></i><span>Search</span></router-link>
                    </li>
                </ul>
            </div>
            <div class="nav-group" :class="{'show':user_menu}" @click="userMenu()" ref="user_id">
                <a href="javascript:void(0)" class="nav-label"><i class="ri-user-settings-line me-2 menu_icon"></i>
                    User Management</a>
                <ul class="nav nav-sidebar" @click.stop="stopMenu()">
                    <li class="nav-item" v-can="'user.create'">
                        <router-link to="/user_create" class="nav-link"><i class="ri-add-box-line"></i> <span>Create
                                User</span></router-link>
                    </li>
                    <li class="nav-item" v-can="'user.user_list'">
                        <router-link to="/users_list" class="nav-link"><i class="ri-group-line"></i> <span>All
                                Users</span></router-link>
                    </li>
                    <li class="nav-item" v-can="'roles.create'">
                        <router-link to="/roles" class="nav-link"><i class="ri-id-card-line"></i> <span>Roles</span></router-link>
                    </li>   
                </ul>
            </div>
            <!-- <div class="nav-group">
                <a href="#" class="nav-label"><i class="ri-user-settings-line me-2 menu_icon"></i> Dashboard</a>
                <ul class="nav nav-sidebar">
                    <li class="nav-item">
                        <a href="file-manager.html" class="nav-link"><i class="ri-folder-2-line"></i> <span>File Manager</span></a>
                    </li>
                </ul>
            </div> -->
            <div class="nav-group" :class="{'show':configuration_menu}" @click="configurationMenu()" ref="config_id">
                <a href="javascript:void(0)" class="nav-label"><i class="ri-settings-5-line me-2 menu_icon"></i>
                    Configuration</a>
                <ul class="nav nav-sidebar" @click.stop="stopMenu()">
                    <li class="nav-item" v-can="'division.create'">
                        <router-link to="/divisions" class="nav-link"><i
                                class="ri-archive-drawer-fill"></i><span>Divisions</span></router-link>
                    </li>
                    <li class="nav-item" v-can="'sub_division.create'">
                        <router-link to="/sub_divisions" class="nav-link"><i class="ri-archive-stack-fill"></i><span>Sub
                                Divisions</span></router-link>
                    </li>
                    <li class="nav-item" v-can="'police_station.create'">
                        <router-link to="/police_stations" class="nav-link"><i class="ri-home-8-fill"></i><span>Police
                                Stations</span></router-link>
                    </li>
                    <li class="nav-item" v-can="'major_head.create'">
                        <router-link to="/major_heads" class="nav-link"><i class="ri-arrow-up-circle-line"></i><span>Major
                                Head</span></router-link>
                    </li>
                    <li class="nav-item" v-can="'minor_head.create'">
                        <router-link to="/minor_heads" class="nav-link"><i class="ri-arrow-down-circle-line"></i><span>Minor
                                Head</span></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/designations" class="nav-link"><i class="ri-user-star-line"></i> <span>Designations</span></router-link>
                    </li>                    
                    <li class="nav-item" v-can="'permission.settings'">
                        <router-link to="/permissions" class="nav-link"><i class="ri-lock-line"></i><span>Permissions</span></router-link>
                    </li>
                </ul>
            </div>
            <div class="nav-group" :class="{'show':report_menu}" @click="reportMenu()" ref="report_id">
                <a href="javascript:void(0)" class="nav-label"><i class="ri-file-chart-line me-2 menu_icon"></i>
                    Reports</a>
                <ul class="nav nav-sidebar" @click.stop="stopMenu()">
                    <li class="nav-item" v-can="'police_station_report.view'">
                        <router-link to="/rowdy_sheeter_report" class="nav-link"><i class="ri-filter-line"></i><span>Police Station Report</span></router-link>
                    </li>
                    <li class="nav-item" v-can="'statistics.view'">
                        <router-link to="/rowdy_sheeter_statistics" class="nav-link"><i class="ri-bar-chart-line"></i><span>Statistics</span></router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="sidebar-footer">
            <div class="sidebar-footer-top">
                <div class="sidebar-footer-thumb">
                    <img src="assets/img/bg1.jpg" alt="" />
                </div>
                <div class="sidebar-footer-body">
                    <h6><a href="javascript:void(0)">{{ $store.getters.user.name }}</a></h6>
                    <p>{{ $store.getters.user.role.role }}</p>
                </div>
                <a id="sidebarFooterMenu" href="#" class="dropdown-link"><i class="ri-arrow-down-s-line"></i></a>
            </div>
            <div class="sidebar-footer-menu">
                <nav class="nav">
                    <router-link to="/profile_update"><i class="ri-user-line"></i> Edit Profile</router-link>
                    <!-- <a href="javascript:void(0)"><i class="ri-edit-2-line"></i> Edit Profile</a> -->
                    <a href="javascript:void(0)" @click="logout()"><i class="ri-logout-box-r-line"></i> Log Out</a>
                </nav>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            gang_menu: true,
            rowdy_menu: false,
            user_menu: false,
            configuration_menu: false,
            report_menu: false
        }
    },

    mounted() {
        if (sessionStorage.getItem('reloaded')) {
            sessionStorage.removeItem('reloaded');
            if (sessionStorage.getItem('path')) {
                this.$router.push(sessionStorage.getItem('path'));
                sessionStorage.removeItem('path');
            }
        } else {
            sessionStorage.setItem('reloaded', '1');
            this.$router.go();
        }
    },

    methods: {
        logout() {
            let vm = this;
            this.$store.dispatch('post', { uri: 'logout' })
                .then(response => {
                    this.$store.dispatch('logout');
                    this.$router.push('/login');
                })
                .catch(error => {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        gangMenu() {
            this.gang_menu = !this.gang_menu;
        },
        rowdyMenu() {
            this.rowdy_menu = !this.rowdy_menu;
        },
        userMenu() {
            this.user_menu = !this.user_menu;
        },
        configurationMenu() {
            this.configuration_menu = !this.configuration_menu;
        },
        reportMenu() {
            this.report_menu = !this.report_menu;
        },
        stopMenu(){},
    }
}
</script>
<style scoped>
.ml-20 {
    margin-left: 20px;
}

.menu_icon {
    font-size: 20px;
    opacity: 0.85;
}
</style>
