<template>
    <div class="d-sm-flex align-items-center justify-content-between mb-2">
        <h2 class="main-title fs-28 mb-0" style="display: inline;">Permissions</h2>
        <button type="button" class="btn btn-primary gap-2 float-end" @click="loadAbilities()">
            <i class="ri-bar-chart-2-line fs-18 lh-1"></i> Add Permissions
        </button>
    </div>
    <div class="row g-3">
        <div class="col-sm-12">
            <div class="card card-one">
                <div class="card-header">
                    <h6 class="card-title">Permissions</h6>
                    <nav class="nav ms-auto">
                        <span class="text-primary fw-bold">Role</span>
                        <select class="form-control" :class="{ 'is-invalid': errors.role_id }" v-model="role_id"
                            @change="getRoleAbilities">
                            <option value="">Select Role</option>
                            <option :value="role.role_id" v-for="role, key in roles">{{role.role}}</option>
                        </select>
                    </nav>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6" v-for="role_ability, i in role_abilities" :key="i">
                            <div class="table-responsive text-wrap">
                                <table class="table table-sm table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th colspan="4" class="bold text-primary">
                                                {{ role_ability[0]?.module.module_name }}
                                            </th>
                                        </tr>
                                        <tr class="text-wrap">
                                            <th width="5%" class="text-center">#</th>
                                            <th width="40%">Ability</th>
                                            <th width="40%">Description</th>
                                            <th width="5%" class="text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="ability, j in role_ability" :key="j">
                                            <td class="text-center">{{ j + 1 }}</td>
                                            <td>{{ ability.ability }}</td>
                                            <td>{{ ability.description }}</td>
                                            <td class="text-center">
                                                <div class=" form-switch">
                                                    <input class="form-check-input " type="checkbox" role="switch"
                                                        :checked="ability.status" :value="ability.status"
                                                        @change="deleteAuthorization(ability)" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            roles: [],
            role_abilities: [],
            errors: [],
            role_id: ''
        };
    },
    mounted() {
        let vm = this;
        vm.role_id = vm.$store?.getters?.user?.role_id;
        if (vm.$store.getters?.user) {
            vm.getRoles();
        }
    },
    methods: {
        getRoles() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store.dispatch('post', { uri: 'getRoles' })
                .then(response => {
                    loader.hide();
                    vm.roles = response.data.data
                    vm.getRoleAbilities();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        },

        getRoleAbilities() {
            let vm = this;
            let loader = vm.$loading.show();
            this.$store.dispatch('post', { uri: 'getPermissionStatus', data: { 'role_id': vm.role_id } })
                .then(response => {
                    loader.hide();
                    vm.role_abilities = response.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                });
        },

        loadAbilities() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store.dispatch('post', { uri: 'loadAbilities' })
                .then(response => {
                    loader.hide();
                    vm.$store.dispatch('success', 'Permissions updated successfully!');
                    vm.getRoleAbilities();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        },

        deleteAuthorization(authorization) {
            let vm = this;
            let uri = {
                uri: 'deleteAuthorization',
                data: {
                    role_id: this.role_id,
                    ability_id: authorization.ability_id,
                    status: authorization.status
                }
            }
            vm.$store.dispatch("post", uri)
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        }
    },
};
</script>
