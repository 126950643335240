<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0">Designation</h4>
        </div>
        <!-- <div class="align-items-center gap-2 ml-auto">
            <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Serach" v-model="meta.search" @keypress.enter="search()" />
        </div> -->
    </div>
    <div class="row g-3 mb-2">
        <div class="col-xl-4" style="height: fit-content;">
            <form class="card card-one" @submit.prevent="submitForm()">
                <div class="card-header">
                    <h6 class="card-title" v-if="status">New Designation</h6>
                    <h6 class="card-title" v-else>Update Designation</h6>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">Designation</label><span class="text-danger"> *</span>
                            <input type="text" class="form-control" placeholder="Designation"
                                :class="{ 'is-invalid': errors.designation }" v-model="designation.designation"
                                ref="designation" />
                            <span v-if="errors.designation" class="invalid-feedback">{{ errors.designation[0] }}</span>
                        </div>
                        <div class="col-md-12">
                            <label class="form-label">Description</label>
                            <textarea class="form-control" placeholder="Enter descrption" v-model="designation.description"
                                :class="{ 'is-invalid': errors.description }" cols="30" rows="2"></textarea>
                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-end">
                    <button type="button" class="btn btn-danger me-2" @click="discard()"><i
                            class="ri-prohibited-line me-1"></i>Discard</button>
                    <button type="submit" class="btn btn-primary">
                        <span v-if="status"> <i class="ri-save-line me-1"></i> Submit</span>
                        <span v-else> <i class="ri-save-line me-1"></i> Update </span>
                    </button>
                </div>
            </form>
        </div>
        <div class="col-xl-8">
            <div class="card card-one">
                <div class="card-header">
                    <h6 class="card-title">Designations</h6>
                </div>
                <div class="card-body">
                    <input type="text" class="form-control mb-2" id="search" placeholder="Search" v-model="meta.search"
                        @keypress.enter="search()">
                    <div class="table-responsive" style="overflow-y:scroll; height: 60vh;">
                        <table class="table text-nowrap table-bordered table-hover table-bottom-border  mb-0">
                            <thead>
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center">Sl No.</th>
                                    <th @click="sort('designation')">
                                        Designation
                                        <span>
                                            <i v-if="meta.keyword == 'designation' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'designation' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="designations.length == 0">
                                    <td colspan="5" class="text-center">No records found</td>
                                </tr>
                                <tr v-for="designation, key in designations" :key="key">
                                    <td class="text-center">{{ meta.from + key }}</td>
                                    <td>{{ designation.designation }}</td>
                                    <td>{{ designation.description }}</td>
                                    <td class="text-center">
                                        <div class="form-switch">
                                            <input class="form-check-input" type="checkbox" designation="switch"
                                                :id="'designation' + designation.designation_id"
                                                :checked="designation.status" :value="designation.status"
                                                @change="deleteDesignation(designation)" />
                                            <label class="custom-control-label"
                                                :for="'designation' + designation.designation_id"></label>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <nav>
                                            <a href="javascript:void(0)" class="text-primary me-2"
                                                v-if="designation.status" @click="editDesignation(designation)"><i
                                                    class="ri-edit-line fs-18 lh-1"></i></a>
                                        </nav>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                        @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            meta: {
                search: "",
                order_by: "asc",
                keyword: "designation_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                to: 1,
                maxPage: 1,
                trashed: false,
            },
            designation: {
                designation_id: "",
                designation: "",
                description: '',
            },
            designations: [],
            status: true,
            errors: [],
        };
    },
    mounted() {
        this.$refs.designation.focus();
        this.index();
    },

    methods: {
        submitForm() {
            let vm = this;
            if (vm.status) {
                vm.create();
            } else {
                vm.update();
            }
        },
        index() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "paginateDesignations", data: vm.meta })
                .then((response) => {
                    vm.designations = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.to = response.data.meta.to;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        create() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store
                .dispatch("post", { uri: "addDesignation", data: vm.designation })
                .then((response) => {
                    loader.hide();
                    vm.$store.dispatch("success", "Designation Added successfully");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        update() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store
                .dispatch("post", { uri: "updateDesignation", data: vm.designation })
                .then((response) => {
                    loader.hide();
                    vm.$store.dispatch("success", "Designation is successfully updated");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        editDesignation(designation) {
            let vm = this;
            vm.$refs.designation.focus();
            vm.errors = [];
            vm.status = false;
            vm.designation = designation;
        },

        deleteDesignation(designation) {
            let vm = this;
            let loader = vm.$loading.show();
            designation.status = designation.status == 1 ? 0 : 1;
            vm.$store
                .dispatch("post", { uri: "deleteDesignation", data: designation })
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", response.data.message);
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        search() {
            let vm = this;
            vm.meta.page = 1;
            vm.index();
        },

        onPageChange(page) {
            let vm = this;
            vm.meta.page = page;
            vm.index();
        },
        sort(field) {
            let vm = this;
            vm.meta.keyword = field;
            vm.meta.order_by = vm.meta.order_by == "asc" ? "desc" : "asc";
            vm.index();
        },
        discard() {
            let vm = this;
            vm.designation.designation = "";
            vm.designation.description = "";
            vm.$refs.designation.focus();
            vm.errors = [];
            vm.status = true;
            vm.index();
        },
        onPerPageChange() {
            this.meta.page = 1;
            this.index();
        },
    },
};
</script>
<style scoped>
input:focus,
select:focus {
    outline: none;
    box-shadow: 0 0 0 0 transparent;
}
</style>