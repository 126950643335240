<template>
    <div class="d-md-flex align-items-center justify-content-between1 gap-4 mt-2 mb-4">
        <h2 class="main-title fs-28 mb-0">All Gangs List</h2>
        <div class="align-items-center gap-2 ml-auto">
            <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Search"
                v-model="meta.search" @keypress.enter="search()" />
        </div>
        <button type="button" v-can="'gang.create'" class="mt-auto btn btn-outline-primary d-flex align-items-center gap-2"
            @click="addGang()">
            <i class="ri-add-line fs-18 lh-1"></i>Create New Gang
        </button>
    </div>
    <div class="row">
        <div class="col-xl-12">
            <div>
                <div class="card card-one">
                    <div class="card-header d-flex justify-content-between">
                        <h5 class="text-primary pt-2"> Total Gangs : {{ meta.totalRows }}</h5>
                        <div>
                            <button v-can="'gang.export_pdf'" class="btn btn-sm btn-success mx-2" @click="downloadGangsPdf()">Export Gangs
                                (pdf)</button>
                            <button v-can="'gang.export_excel'" class="btn btn-sm btn-success" @click="downloadGangs()">Export Gangs
                                (excel)</button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive p-0" ref="scroll_gangs" style="overflow-y:scroll; height: 60vh;"
                            @scroll="onScroll($event)">
                            <table class="table table-hover table-bottom-border table-striped">
                                <thead class="freeze-head">
                                    <tr style="background-color: #f5f5f5;" class="fw-bold">
                                        <th v-can="'gang.update'">Edit</th>
                                        <th class="text-center">Sl No.</th>
                                        <th class="text-center">
                                            Photo
                                        </th>
                                        <th @click="sort('gang_name')">Gang Name
                                            <span>
                                                <i v-if="meta.keyword == 'gang_name' && meta.order_by == 'asc'"
                                                    class="ri-sort-alphabet-asc"></i>
                                                <i v-else-if="meta.keyword == 'gang_name' && meta.order_by == 'desc'"
                                                    class="ri-sort-alphabet-desc"></i>
                                                <i v-else class="ri-arrow-up-down-line"></i>
                                            </span>
                                        </th>
                                        <th @click="sort('rowdy_sheeter_name')">Leader
                                            <span>
                                                <i v-if="meta.keyword == 'rowdy_sheeter_name' && meta.order_by == 'asc'"
                                                    class="ri-sort-alphabet-asc"></i>
                                                <i v-else-if="meta.keyword == 'rowdy_sheeter_name' && meta.order_by == 'desc'"
                                                    class="ri-sort-alphabet-desc"></i>
                                                <i v-else class="ri-arrow-up-down-line"></i>
                                            </span>
                                        </th>
                                        <th>Associates</th>
                                        <th>Opposite Gangs</th>
                                        <th class="text-center" v-can="'gang.view'">View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="gang, key in gangs">
                                        <td class="text-center" v-can="'gang.update'">
                                            <nav>
                                                <router-link :to="'/update_gang/' + gang.gang_id"
                                                    class="text-primary me-2" title="Edit gang"><i
                                                        class="ri-edit-line fs-18 lh-1"></i></router-link>
                                            </nav>
                                        </td>
                                        <td class="text-center">{{ meta.from + key }}</td>
                                        <!-- <td>
                                            <router-link :to="'/gang_details/' + gang.gang_id"
                                                class="text-primary me-2">
                                                {{ gang.gang_name }}
                                            </router-link>
                                        </td> -->
                                        <td><img :src="gang?.leader?.rowdy?.rowdy_sheeter_photo" alt="" width="80px">
                                        </td>
                                        <td style="text-transform: uppercase;">
                                            <router-link :to="'/gang_details/' + gang.gang_id"
                                                class="text-primary me-2">
                                                <Highlighter class="my-highlight" highlightClassName="highlight"
                                                    :searchWords="keywords" :autoEscape="true"
                                                    :textToHighlight="gang.gang_name" />
                                            </router-link>
                                        </td>
                                        <!-- <td>{{ gang.leader?.rowdy?.rowdy_sheeter_name }}</td> -->
                                        <td style="text-transform: uppercase;">
                                            <Highlighter class="my-highlight" highlightClassName="highlight"
                                                :searchWords="keywords" :autoEscape="true"
                                                :textToHighlight="gang.leader?.rowdy?.rowdy_sheeter_name" />
                                        </td>
                                        <td>
                                            <a href="javascript:void(0)" data-bs-toggle="modal"
                                                data-bs-target="#membersModal" @click="getAssociates(gang.associates)">
                                                <div class="mutual-badge badge rounded-pill bg-light-blue">
                                                    <template v-if="gang.associates.length < 4">
                                                        <ul>
                                                            <li v-for="associate, key in gang.associates">
                                                                <a href="" class="avatar"><img
                                                                        :src="associate?.rowdy?.rowdy_sheeter_photo"
                                                                        alt="" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <label v-if="gang.associates.length == 1" class="text-dark">{{
                                                            gang.associates.length }}
                                                            Member</label>
                                                        <label v-else class="text-dark">{{ gang.associates.length }}
                                                            Members</label>
                                                    </template>
                                                    <template v-else>
                                                        <ul>
                                                            <li v-for="i in 3">
                                                                <a href="" class="avatar"><img
                                                                        :src="gang.associates[i].rowdy.rowdy_sheeter_photo"
                                                                        alt="" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <label class="text-dark">+ {{ gang.associates.length - 3 }} = {{
                                                            gang.associates.length }} Members</label>
                                                    </template>
                                                </div>
                                            </a>
                                        </td>
                                        <td><span v-for="opposite_gang, key in gang.opposite_gangs">{{
                                            opposite_gang.gang_name }}, </span></td>
                                        <td class="text-center" v-can="'gang.view'">
                                            <nav>
                                                <router-link :to="'/gang_details/' + gang.gang_id"
                                                    class="text-primary me-2" title="View Gang"><i
                                                        class="ri-eye-line fs-18 lh-1"></i></router-link>
                                            </nav>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer">
                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                            @pagechanged="onPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade bd-example-modal-lg" tabindex="-1" id="membersModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">ASSOCIATES</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table table-sm table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th class="text-center">SL No</th>
                                    <th class="text-center">Photo</th>
                                    <th>Name</th>
                                    <th>FPB COID Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="associate, key in associates" :key="key">
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td class="text-center">
                                        <img :src="associate.rowdy.rowdy_sheeter_photo" width="50px" alt="">
                                    </td>
                                    <td>{{ associate.rowdy.rowdy_sheeter_name }}</td>
                                    <td>{{ associate.rowdy.fpb_coid_number }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import Highlighter from 'vue-highlight-words';
export default {
    components: {
        Pagination, Highlighter
    },
    data() {
        return {
            meta: {
                search: '',
                order_by: "asc",
                keyword: "gang_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
            },
            errors: [],
            gangs: [],
            associates: [],
            restore_page: false,
            scroll_top: 0
        }
    },
    mounted() {
        if (sessionStorage.getItem('gang_meta') && this.restore_page) {
            this.meta = JSON.parse(sessionStorage.getItem('gang_meta'));
            sessionStorage.removeItem('gang_meta');
        }
        if (sessionStorage.getItem('gangs') && this.restore_page) {
            this.gangs = JSON.parse(sessionStorage.getItem('gangs'));
            sessionStorage.removeItem('gangs');
        }
        if (sessionStorage.getItem('scroll_top') && this.restore_page) {
            this.scroll_top = sessionStorage.getItem('scroll_top');
            sessionStorage.removeItem('scroll_top');
        }
        this.index();
    },

    unmounted() {
        sessionStorage.setItem('gang_meta', JSON.stringify(this.meta));
        sessionStorage.setItem('gangs', JSON.stringify(this.gangs));
        sessionStorage.setItem('scroll_top', this.scroll_top);
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name == 'GangDetails' || from.name == 'UpdateGang') {
                vm.restore_page = true;
            }
        })
    },
    computed: {
        keywords() {
            return this.meta.search.split(' ')
        }
    },
    methods: {
        index() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'paginateGangs', data: this.meta })
                .then(response => {
                    loader.hide();
                    // let temp = response.data.data;
                    if (!vm.restore_page) {
                        // temp.forEach(ele => vm.gangs.push(ele));
                        vm.gangs = response.data.data;
                    }
                    this.meta.totalRows = response.data.meta.total;
                    this.meta.lastPage = response.data.meta.last_page;
                    this.meta.from = response.data.meta.from;
                    this.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    if (vm.restore_page) {
                        vm.$refs.scroll_gangs.scrollTop = vm.scroll_top;
                        vm.restore_page = false;
                    }
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                })
        },
        addGang() {
            this.$router.push('/create_gang');            
        },

        deleteGang(gang_id) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'deleteGang', data: { gang_id: gang_id } })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    this.index();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        onPageChange(page) {
            this.meta.page = page;
            this.index();
        },

        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },

        search() {
            this.gangs = [];
            this.meta.page = 1;
            this.index();
        },

        getAssociates(associates) {
            this.associates = associates;
        },

        onScroll(e) {
            const scroll = e.target.offsetHeight + e.target.scrollTop;
            const height = e.target.scrollHeight - 1;
            this.scroll_top = e.target.scrollTop;

            // if (scroll >= height) {
            //     if (this.meta.page < this.meta.lastPage) {
            //         this.meta.page++;
            //         this.index();
            //         e.target.scrollTop = e.target.scrollTop - 10;
            //     }
            // }
        },

        downloadGangs() {
            window.open(this.$store.getters.downloadUrl + "exportGangs");
        },

        downloadGangsPdf() {
            window.open(this.$store.getters.downloadUrl + "exportGangsPdf");
        }
    }
}
</script>
<style scoped>
.card-one {
    border-width: 0px;
}

@media only screen and (max-width: 767px) {
    .gap-2 {
        margin-bottom: 0.5rem;
    }
}

thead {
    /* position: relative !important; */
    top: -1px !important;
    z-index: 5 !important;
    background-color: #f3f5f9
}
</style>
