<template>
	<div v-if="$store.getters.user">
		<Sidebar></Sidebar>
		<div class="main main-app p-4 pt-2 mt-0" style="position: relative; background-color: #f8f8f8;">
			<!-- <a id="menuSidebar" href="#" class="menu-link me-3 me-lg-4 fs-24"><i class="ri-menu-2-fill"></i></a> -->
			<img src="assets/img/bg3.png" class="img2" style="position: absolute;">

			<div><a id="menuSidebar" href="#" class="menu-link me-3 me-lg-4"><i class="ri-menu-2-fill"></i></a></div>

			<router-view />
		</div>
	</div>
	<div v-else>
		<img src="assets/img/bg3.png" class="img2" style="position: absolute;">
		<router-view />
	</div>
</template>
<script>
import Sidebar from "./components/Sidebar.vue";
import Footer from "./components/Footer.vue";
export default {
	name: 'App',
	components: { Sidebar, Footer },
	created() {
		if (sessionStorage.getItem("user")) {
			this.$store.dispatch('setUser', JSON.parse(sessionStorage.getItem("user")))
			sessionStorage.removeItem('user')
		}
		if (sessionStorage.getItem("token")) {
			this.$store.dispatch('setToken', sessionStorage.getItem("token"))
			sessionStorage.removeItem('token')
		}
		if (sessionStorage.getItem("permissions")) {
			this.$store.dispatch('setPermissions', JSON.parse(sessionStorage.getItem("permissions")));
		}
		window.addEventListener("beforeunload", () => {
			sessionStorage.setItem("user", JSON.stringify(this.$store?.getters?.user));
			sessionStorage.setItem("token", this.$store?.getters?.token);
			sessionStorage.setItem("permissions", JSON.stringify(this.$store?.getters?.permissions));
		})
	}
};
</script>
<style scoped>
.img2 {
	opacity: 15%;
	width: 163px;
	height: 122px;
	bottom: 8px;
	right: 16px;
}

@media(min-width:991px){
      #menuSidebar{
        display:none;
      }
    }
	#menuSidebar{
		font-size: 24px;
	}

</style>