<template>
    <div class="d-md-flex align-items-center justify-content-between1 gap-4 mb-4">
        <h2 class="main-title fs-28 mb-0">All Rowdies List</h2>
        <div class="form-group align-items-center gap-2 ml-auto">
            <div class="d-flex justify-content-between">
                <label class="form-label fw-500 fs-12 font-gray ml-10">Police Station <span class="fw-bold"
                        style="color: green;">[ {{ meta.police_station_ids.length }} ]</span></label>
                <label><a href="javascrip:void(0)" class="fw-500 small text-danger" @click="clearSelection()">Reset</a></label>
            </div>
            <!-- <div class="dropdown" @click="showDropdown">
                <div class="overselect"></div>
                <select class="form-control">
                    <option value="">Select Police Station</option>
                </select>
            </div>
            <div class="multiselect" v-if="show">
                <ul>
                    <li class="" v-for="(police_station, index) in police_stations" :key="index">
                        <input type="checkbox" :value="police_station.police_station_id" style="padding: 2px;"
                            @change="onPoliceStationSelected()" v-model="meta.police_station_ids"
                            v-if="police_station.v_flag" />
                        <label style="margin-left: 5px;" v-if="police_station.v_flag">{{ police_station.police_station
                            }}</label>
                    </li>
                </ul>
            </div> -->

            <MultiSelect v-model="selected_police_stations" filter optionLabel="police_station"
                :options="police_stations" placeholder="Select Police Station" :maxSelectedLabels="3" class="w-250"
                style="height: 35px; " @change="PoliceStaionFilter(selected_police_stations)" />
        </div>
        <div class="align-items-center gap-2">
            <label class="form-label fw-500 fs-12 font-gray w-200 ml-15">Search with Name, Mobile No, Coid No, Aadhaar No, Voter No</label>
            <input type="text" class="form-control" placeholder="Search"
                v-model="meta.search" @keypress.enter="search()" />
        </div>
        <button type="button" class="mt-auto btn btn-outline-primary d-flex align-items-center gap-2"
            @click="addRowdy()"><i class="ri-add-line fs-18 lh-1"></i>Add New Rowdy
        </button>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-header d-flex justify-content-between">
                    <h5 class="text-primary pt-2"> Total Rowdy Sheeters : {{ meta.totalRows }}</h5>
                    <div>
                        <label class="form-label mx-2" style="display: inline;">Filter by total no. of Cases</label>
                        <input type="text" v-model="meta.case_count.from" @keydown="isNumber($event)"
                            @keypress.enter="filterByCase()" style="width: 100px; display: inline;"
                            class="form-control form-control-sm" placeholder="From">
                        <input type="text" v-model="meta.case_count.to" @keydown="isNumber($event)"
                            @keypress.enter="filterByCase()" style="width: 100px; display: inline;"
                            class="form-control form-control-sm mx-2" placeholder="To">
                        <button v-can="'rowdy.export_pdf'" class="btn btn-sm btn-success mx-2" @click="downloadRowdiesPdf()">Export
                            (pdf)</button>
                        <button v-can="'rowdy.export_excel'" class="btn btn-sm btn-success" @click="downloadRowdies()">Export
                            (excel)</button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive" ref="rowdy_scroll" style="overflow-y:scroll; height: 60vh;"
                        @scroll="onScroll($event)">
                        <table class="table table-hover table-sm table-bordered table-striped mb-0">
                            <thead class="freeze-head text-center">
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center" rowspan="2" v-can="'rowdy.update'">Edit</th>
                                    <th class="text-center" rowspan="2">Sl No.</th>
                                    <th class="text-center" rowspan="2">Photo</th>
                                    <th @click="sort('rowdy_sheeter_name')" rowspan="2">Rowdy Names
                                        <span>
                                            <i v-if="meta.keyword == 'rowdy_sheeter_name' && meta.order_by == 'asc'"
                                                class="ri-sort-alphabet-asc"></i>
                                            <i v-else-if="meta.keyword == 'rowdy_sheeter_name' && meta.order_by == 'desc'"
                                                class="ri-sort-alphabet-desc"></i>
                                            <i v-else class="ri-arrow-up-down-line"></i>
                                        </span>
                                    </th>
                                    <th rowspan="2">Alias</th>
                                    <th @click="sort('father_name')" rowspan="2">Father Name
                                        <span>
                                            <i v-if="meta.keyword == 'father_name' && meta.order_by == 'asc'"
                                                class="ri-sort-alphabet-asc"></i>
                                            <i v-else-if="meta.keyword == 'father_name' && meta.order_by == 'desc'"
                                                class="ri-sort-alphabet-desc"></i>
                                            <i v-else class="ri-arrow-up-down-line"></i>
                                        </span>
                                    </th>
                                    <th rowspan="2" class="th-lg">Rowdy Age</th>
                                    <th rowspan="2" class="th-lg">Gender</th>
                                    <th rowspan="2" class="th-lg">Locality</th>
                                    <!-- <th rowspan="2" class="th-lg">Division</th>
                                    <th rowspan="2" class="th-lg">Sub Division</th> -->
                                    <th rowspan="2" class="th-lg text-center">Category<br>&nbsp;&nbsp;&nbsp;Police
                                        Station&nbsp;&nbsp;&nbsp;</th>
                                    <th rowspan="2">Mobile</th>
                                    <th rowspan="2">Aadhaar</th>
                                    <th style="vertical-align: bottom !important" class="text-center" colspan="4">No. Of
                                        Cases</th>
                                    <th rowspan="2" class="text-center">Pending <br> Warrants/ <br>Proclamations</th>
                                    <th class="text-center" rowspan="2" v-can="'rowdy.delete'">Delete</th>
                                </tr>
                                <tr style="background-color: #f5f5f5;" class="text-center">
                                    <th style="vertical-align: top !important">MURDER</th>
                                    <th style="vertical-align: top !important">ATTEMPT<br>TO MURDER</th>
                                    <th style="vertical-align: top !important">Other <br> Cases</th>
                                    <th style="vertical-align: top !important">Total</th>
                                    <!-- <th style="vertical-align: top !important">Proclamations</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="rowdy, key in rowdy_list" :key="key">
                                    <tr>
                                        <td class="text-center" v-can="'rowdy.update'">
                                            <nav>
                                                <router-link :to="'/update_cases_processes/' + rowdy.rowdy_id"
                                                    href="javascript:void(0)" class="text-primary me-2"
                                                    title="Edit Rowdy"><i
                                                        class="ri-edit-line fs-18 lh-1"></i></router-link>                                               

                                                <a href="javascript:void(0)" class="text-success me-2" v-can="'rowdy.remarks'"
                                                    title="User Remarks" data-bs-toggle="modal"
                                                    data-bs-target="#viewRemarks"
                                                    @click="getUserRemarks(rowdy.rowdy_id)"><i
                                                        class="ri-list-check fs-18 lh-1"></i></a>
                                            </nav>
                                        </td>
                                        <td class="text-center">{{ meta.from + key }}
                                        </td>
                                        <td class="text-center">
                                            <a href="javascript:void(0)" data-bs-toggle="modal"
                                                data-bs-target="#enlargeImageModal"
                                                @click="getRowdyImages(rowdy.rowdy_id)">
                                                <img :src="rowdy.rowdy_sheeter_photo" alt="" width="40px">
                                            </a>
                                        </td>
                                        <!-- <td >
                                        <router-link :to="'/view_rowdy/' + rowdy.rowdy_id" class="text-primary me-2">
                                            {{ rowdy.rowdy_sheeter_name }}
                                        </router-link>
                                    </td> -->
                                        <td style="text-transform: uppercase;">
                                            <router-link :to="'/view_rowdy/' + rowdy.rowdy_id"
                                                class="text-primary me-2">
                                                <Highlighter class="my-highlight" highlightClassName="highlight"
                                                    :searchWords="keywords" :autoEscape="true"
                                                    :textToHighlight="rowdy.rowdy_sheeter_name" />
                                            </router-link>
                                        </td>
                                        <td style="text-transform: uppercase;">
                                            {{ rowdy.alias }}</td>
                                        <!-- <td >
                                        <Highlighter class="my-highlight" highlightClassName="highlight"
                                            :searchWords="keywords" :autoEscape="true" :textToHighlight="rowdy.alias" />
                                    </td> -->
                                        <!-- <td  class="text-center">{{ rowdy.gender }}</td> -->
                                        <td>{{ rowdy.father_name }}</td>
                                        <td class="text-center">{{ rowdy.age }}
                                        </td>
                                        <td class="text-center">{{ rowdy.gender
                                            }}</td>
                                        <td class="text-center text-nowrap">{{
                                            rowdy.locality }}</td>
                                        <!-- <td  class="text-center">{{ rowdy.police_station?.division?.division }}</td> -->
                                        <!-- <td  class="text-center"><span v-html="policeDivisionCategories(rowdy.rowdy_categories)"></span></td> -->
                                        <!-- <td  class="text-center">{{ rowdy.police_station?.sub_division?.sub_division }}</td> -->
                                        <!-- <td  class="text-center"><span v-html="policeSubDivisionCategories(rowdy.rowdy_categories)"></span></td> -->
                                        <td class="small text-nowrap text-uppercase"><span
                                                v-html="policeStationCategories(rowdy.rowdy_categories)"></span></td>
                                        <td>{{ rowdy.mobile_no }}</td>
                                        <td>{{ rowdy.aadhaar_number }}</td>
                                        <td class="text-center">
                                            <a href="javascript:void(0)" data-bs-toggle="modal"
                                                data-bs-target="#ipcModal"
                                                @click="getIpcCases(rowdy.rowdy_id, 'MURDER')">{{ rowdy.count_murder_cases }}</a>
                                        </td>
                                        <td class="text-center">
                                            <a href="javascript:void(0)" data-bs-toggle="modal"
                                                data-bs-target="#ipcModal"
                                                @click="getIpcCases(rowdy.rowdy_id, 'ATTEMPT_TO_MURDER')">{{ rowdy.count_attempt_to_murder_cases }}</a>
                                        </td>
                                        <td class="text-center">
                                            <a href="javascript:void(0)" data-bs-toggle="modal"
                                                data-bs-target="#ipcModal"
                                                @click="getIpcCases(rowdy.rowdy_id, 'Others')">{{ rowdy.no_of_cases -
                                                    (rowdy.count_murder_cases + rowdy.count_attempt_to_murder_cases) }}</a>
                                        </td>
                                        <td class="text-center">
                                            <a href="javascript:void(0)" data-bs-toggle="modal"
                                                data-bs-target="#ipcModal"
                                                @click="getIpcCases(rowdy.rowdy_id, 'total')">{{ rowdy.cases_type_total
                                                }}</a>
                                        </td>
                                        <td class="text-center">
                                            <a href="javascript:void(0)" data-bs-toggle="modal"
                                                data-bs-target="#pendingProcesses"
                                                @click="getPendingProcesses(rowdy.rowdy_id)">
                                                {{ rowdy.process_pending_count }}
                                            </a>
                                        </td>
                                        <td class="text-center" v-can="'rowdy.delete'">
                                            <nav v-if="rowdy.can_edit">
                                                <!-- <router-link :to="'/view_rowdy/' + rowdy.rowdy_id"
                                                class="text-success me-2"><i
                                                    class="ri-eye-line fs-18 lh-1"></i></router-link> -->

                                                <a href="javascript:void(0)" class="text-danger"
                                                    @click="deleteRowdy(rowdy.rowdy_id)" title="Delete Rowdy"><i
                                                        class="ri-delete-bin-6-line fs-18 lh-1"></i></a>
                                            </nav>
                                        </td>
                                    </tr>
                                    <!-- <template v-for="category, cat in rowdy?.rowdy_categories">
                                        <template>
                                            <tr v-if="cat > 0">
                                                <td>{{ cat }}</td>
                                            </tr>
                                        </template>
</template> -->
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer d-flex justify-content-between">
                    <div>
                        <select class="form-select border-0 border-bottom" v-model="meta.per_page" @change="setPerPage()">
                            <option value="">Select Per Page</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div>
                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                        @pagechanged="onPageChange" />
                    </div>                    
                </div>
            </div>
        </div>
    </div>
    <!-- View remarks modal -->
    <div class="modal fade" id="viewRemarks" tabindex="-1" aria-labelledby="viewRemarksModal" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="viewRemarksModal">Remarks</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <textarea name="" class="form-control" id="" cols="30" rows="2"
                                        placeholder="Enter user remarks" v-model="user_remarks"></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <button v-if="edit_remarks" class="btn btn-sm float-end btn-primary mt-2"
                                        @click="updateUserRemarks()">Update
                                        Remarks</button>
                                    <button v-else class="btn btn-sm float-end btn-primary mt-2"
                                        @click="addUserRemarks()">Add
                                        Remarks</button>
                                </div>
                            </div>
                            <div class="table-responsive mt-2">
                                <table class="table table-sm table-bordered table-striped">
                                    <thead>
                                        <tr class="text-center">
                                            <th class="text-center">SL NO</th>
                                            <th>LAST UPDATED</th>
                                            <th>USER</th>
                                            <th>REMARKS</th>
                                            <th>ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="remarks.length" v-for="remark, key in remarks" :key="key" class="">
                                            <td width="10%" class="text-center">{{ key + 1 }}</td>
                                            <td class="text-center">{{ moment(remark.updated_at).format('DD-MM-YYYY') }}
                                            </td>
                                            <td>{{ remark.user.name }}</td>
                                            <td width="50%">{{ remark.remarks }}</td>
                                            <td class="text-center" width="15%" style="vertical-align: middle;">
                                                <nav>
                                                    <button v-if="$store.getters.user.user_id == remark.user_id"
                                                        class="btn btn-sm btn-outline-danger me-2"
                                                        @click="deleteUserRemarks(remark.user_remark_id)"><i
                                                            class="ri-chat-delete-line"></i></button>
                                                    <button v-if="$store.getters.user.user_id == remark.user_id"
                                                        class="btn btn-sm btn-outline-primary"
                                                        @click="editRemarks(remark)"><i
                                                            class="ri-pencil-line"></i></button>
                                                </nav>
                                            </td>
                                        </tr>
                                        <tr v-else class="">
                                            <td class="text-center" colspan="5">No records to display!</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        @click="discardRemarks()">Close</button>
                    <!-- <button type="button" class="btn btn-primary">Add Remarks</button> -->
                </div>
            </div>
        </div>
    </div>
    <!-- Goonda act / externment modal -->
    <div class="modal fade bd-example-modal-lg" tabindex="-1" id="ipcModal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">IPC {{ ipc }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table table-sm table-bordered table-striped">
                            <thead class="text-center">
                                <tr>
                                    <th>SL NO</th>
                                    <th>Police Station</th>
                                    <th>Crime Number</th>
                                    <th>Section of Law</th>
                                    <th>FIR Stage / <br> Warrant Issued</th>
                                    <th>Victim/Deceased</th>
                                    <th>Accomplices Names</th>
                                    <th>Case Status</th>
                                    <th>Court Name</th>
                                    <th>CC / SC Number</th>
                                    <th>Next Hearing Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="ipc_case, key in ipc_cases">
                                    <td>{{ key + 1 }}</td>
                                    <td v-if="ipc_case.police_station?.police_station == 'Others'">{{
                                        ipc_case.other_police_station }}</td>
                                    <td v-else>{{ ipc_case.police_station?.police_station }}</td>
                                    <td>{{ ipc_case.crime_number }}</td>
                                    <td>{{ ipc_case.section_of_law }}</td>
                                    <td>{{ ipc_case.warrant_issued ? 'Yes' : 'No' }}</td>
                                    <td>{{ ipc_case.victim_deceased }}</td>
                                    <td>{{ ipc_case.accomplices_names }}</td>
                                    <td>{{ ipc_case.case_status }}</td>
                                    <td>{{ ipc_case.court_name }}</td>
                                    <td>{{ ipc_case.cc_sc_number }}</td>
                                    <td>{{ ipc_case.next_hearing_date }}</td>
                                    <td><button class="btn btn-sm btn-primary"
                                            @click="editIpcCase(ipc_case.rowdy_id, ipc_case.rowdies_case_id)"><i
                                                class="ri-pencil-line"></i></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        ref="ipc_modal_close">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!-- pending processes modal -->
    <div class="modal fade bd-example-modal-lg" tabindex="-1" id="pendingProcesses">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">IPC {{ ipc }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="small mt-2">
                        <div class="table-responsive">
                            <table class="table table-sm table-bordered table-striped">
                                <thead class="text-center">
                                    <tr>
                                        <th class="text-center">Sl No.</th>
                                        <th>Process Type</th>
                                        <th>Police Station</th>
                                        <th>Crime Number</th>
                                        <th>Section of Law</th>
                                        <th>Court Name</th>
                                        <th>CC / SC Number</th>
                                        <th>Issued Date</th>
                                        <th>Served/Unserved</th>
                                        <th>Next Hearing Date</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="process, key in pending_processes" v-if="pending_processes.length">
                                        <td class="text-center">{{ key + 1 }}</td>
                                        <td>{{ process.process_type }}</td>
                                        <td>{{ process.police_station?.police_station }}</td>
                                        <td>{{ process.crime_number }}</td>
                                        <td>{{ process.section_of_law }}</td>
                                        <td>{{ process.court_name }}</td>
                                        <td>{{ process.cc_sc_number }}</td>
                                        <td>{{ process.issued_date }}</td>
                                        <td>{{ process.status }}</td>
                                        <td>{{ process.next_hearing_date }}</td>
                                        <td>
                                            <a href="javascript:void(0)"
                                                @click="editProcess(process.process_id, process.rowdy_id)"><i
                                                    class="ri-edit-line fs-18 lh-1" title="Edit rowdy"></i></a>
                                        </td>
                                    </tr>
                                    <tr v-else>
                                        <td colspan="11" class="text-center">No records to display!...</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        ref="ipc_modal_close">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Enlarge image -->
    <div class="modal fade bd-example-modal-lg" tabindex="-1" id="enlargeImageModal">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Rowdy Images</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-2" style="display: flex; align-items: center;">
                            <button v-if="this.shift.index > 0" class="btn btn-sm btn-outline-primary float-start"
                                @click="shifLeft()"> <i class="ri-arrow-left-line"></i> </button>
                        </div>
                        <div class="col-8">
                            <img class="modal-img" :src="shift.image" alt="" />
                        </div>
                        <div class="col-2" style="display: flex; align-items: center;">
                            <button v-if="shift.index <= rowdy_images.length - 2"
                                class="btn btn-sm btn-outline-primary float-end" @click="shiftRight()"> <i
                                    class="ri-arrow-right-line"></i> </button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import Highlighter from 'vue-highlight-words';
import MultiSelect from 'primevue/multiselect';
import moment from "moment";
export default {
    components: {
        Pagination, Highlighter, MultiSelect
    },
    name: "RowdiesCreate",
    data() {
        return {
            rowdy_list: [],
            rowdies: [],
            rowdy_id: '',
            restore_page: false,
            meta: {
                search: '',
                order_by: "asc",
                keyword: "rowdy_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
                police_station_ids: [],
                case_count: {
                    from: '',
                    to: ''
                }
            },
            remarks: [],
            user_remarks: '',
            moment: moment,
            edit_remarks: false,
            user_remark_id: '',
            scroll_top: 0,
            police_stations: [],
            show: false,
            ipc_cases: [],
            search_police_station: '',
            rowdy_image: '',
            rowdy_images: [],
            shift: {
                index: 0,
                image: ''
            },
            search_police_station: '',
            selected_police_stations: [],
            pending_processes: []
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.getPoliceStations();
            if (sessionStorage.getItem('paths')) {
                sessionStorage.removeItem('paths');
            }
            if (from.name == 'ViewRowdy' | from.name == 'UpdateCases') {
                vm.restore_page = true;
            }
        });
    },

    mounted() {
        if (sessionStorage.getItem('rowdy_meta') && this.restore_page) {
            this.meta = JSON.parse(sessionStorage.getItem('rowdy_meta'));
            sessionStorage.removeItem('rowdy_meta');
        }
        if (sessionStorage.getItem('rowdy_list') && this.restore_page) {
            this.rowdy_list = JSON.parse(sessionStorage.getItem('rowdy_list'));
            sessionStorage.removeItem('rowdy_list');
        }
        if (sessionStorage.getItem('scroll_top') && this.restore_page) {
            this.scroll_top = sessionStorage.getItem('scroll_top');
            sessionStorage.removeItem('scroll_top');
        }
        this.getRowdyList();
    },

    unmounted() {
        sessionStorage.setItem('rowdy_meta', JSON.stringify(this.meta));
        sessionStorage.setItem('rowdy_list', JSON.stringify(this.rowdy_list));
        sessionStorage.setItem('scroll_top', this.scroll_top);
    },

    computed: {
        keywords() {
            return this.meta.search.split(' ')
        }
    },
    methods: {
        getRowdyList() {
            let vm = this;
            let uri = { uri: "paginateRowdies", data: vm.meta };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    // vm.rowdy_list = response.data.data;
                    // let temp = response.data.data;
                    if (!vm.restore_page) {
                        // temp.forEach(ele => vm.rowdy_list.push(ele));
                        vm.rowdy_list = response.data.data;
                    }
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    if (vm.restore_page) {
                        vm.$refs.rowdy_scroll.scrollTop = vm.scroll_top;
                        vm.restore_page = false;
                    }
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        editRowdy(rowdy_id) {
            this.$router.push("/rowdies/edit/" + rowdy_id);
        },

        addRowdy() {
            this.$router.push('/create_rowdy');
        },

        deleteRowdy(rowdy_id) {
            if (confirm("Are you sure you want to delete")) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: 'deleteRowdy', data: { 'rowdy_id': rowdy_id } };
                vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", response.data.message);
                        vm.getRowdyList();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },

        viewRowdyGang(rowdy_id) {
            this.$router.push('cases/rowdy/' + rowdy_id);
        },

        onPageChange(page) {
            this.meta.page = page;
            this.getRowdyList();
        },

        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.getRowdyList();
        },
        search() {
            this.rowdy_list = [];
            this.meta.page = 1;
            this.getRowdyList();
        },

        policeStationCategories(categories) {
            let station_category = [];
            if (categories.length) {
                categories.forEach(ele => {
                    // let str = ele.category + ' - ' + ele.police_station.police_station +' | '+ ele.police_station.sub_division.sub_division+' | '+ ele.police_station.division.division;
                    let str = ele.category + ' - ' + ele.police_station.police_station;
                    station_category.push(str);
                });
            }
            station_category.sort();
            return station_category.join(",<br>");
        },

        policeDivisionCategories(categories) {
            let division_category = [];
            if (categories.length) {
                categories.forEach(ele => {
                    let str = ele.category + ' - ' + ele.police_station.division.division;
                    division_category.push(str);
                });
            }
            division_category.sort();
            return division_category.join(",<br>");
        },

        policeSubDivisionCategories(categories) {
            let sub_division_category = [];
            if (categories.length) {
                categories.forEach(ele => {
                    let str = ele.category + ' - ' + ele.police_station.sub_division.sub_division;
                    sub_division_category.push(str);
                });
            }
            sub_division_category.sort();
            return sub_division_category.join(",<br>");
        },

        onScroll(e) {
            const scroll = e.target.offsetHeight + e.target.scrollTop;
            const height = e.target.scrollHeight - 1;
            this.scroll_top = e.target.scrollTop;
            // if (scroll >= height) {
            //     if (this.meta.page < this.meta.lastPage) {
            //         this.meta.page++;
            //         this.getRowdyList();
            //     }
            //     e.target.scrollTop = e.target.scrollTop - 10;
            // }
        },

        downloadRowdies() {
            let police_station_ids = JSON.stringify(this.meta.police_station_ids);
            window.open(this.$store.getters.downloadUrl + "exportRowdies?police_station_ids="+police_station_ids+"&from="+this.meta.case_count.from+"&to="+this.meta.case_count.to);
        },

        downloadRowdiesPdf() {
            let police_station_ids = JSON.stringify(this.meta.police_station_ids);
            window.open(this.$store.getters.downloadUrl + "exportRowdiesPdf?police_station_ids="+police_station_ids+"&from="+this.meta.case_count.from+"&to="+this.meta.case_count.to);
        },

        getUserRemarks(rowdy_id) {
            this.rowdy_id = rowdy_id;
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getUserRemarks', data: { rowdy_id: this.rowdy_id } })
                .then(response => {
                    loader.hide();
                    this.remarks = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        addUserRemarks() {
            if (this.user_remarks == '') {
                alert('Remarks field cannot be empty!');
                return;
            }
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'addUserRemarks', data: { rowdy_id: this.rowdy_id, remarks: this.user_remarks } })
                .then(response => {
                    loader.hide();
                    this.remarks = response.data.data;
                    this.user_remarks = '';
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        deleteUserRemarks(user_remark_id) {
            if (!confirm('This remark will be deleted permanantly!')) {
                return;
            }
            let vm = this;
            let loader = this.$loading.show();
            let data = { rowdy_id: this.rowdy_id, user_remark_id: user_remark_id };
            this.$store.dispatch('post', { uri: 'deleteUserRemarks', data: data })
                .then(response => {
                    loader.hide();
                    this.remarks = response.data.data;
                    this.user_remarks = '';
                })
                .catch(error => {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        editRemarks(remark) {
            this.edit_remarks = true;
            this.user_remarks = remark.remarks;
            this.user_remark_id = remark.user_remark_id;
        },

        updateUserRemarks() {
            this.edit_remarks = false;
            let vm = this;
            let data = { rowdy_id: this.rowdy_id, remarks: this.user_remarks, user_remark_id: this.user_remark_id }
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'updateUserRemarks', data: data })
                .then(response => {
                    loader.hide();
                    this.remarks = response.data.data;
                    this.user_remarks = '';
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        discardRemarks() {
            this.user_remarks = '';
        },

        getPoliceStations() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getPoliceStations' })
                .then(response => {
                    loader.hide();
                    this.police_stations = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },
        showDropdown() {
            // this.show = !this.show;
            this.show = true;
        },

        hideDropdown() {
            this.show = false;
        },

        onPoliceStationSelected() {
            this.rowdy_list = [];
            this.restore_page = false;
            this.getRowdyList();
        },

        clearSelection() {
            this.meta.police_station_ids = [];
            this.selected_police_stations = [];
            this.meta.search = '';
            this.meta.page = 1;
            this.meta.case_count.from = '';
            this.meta.case_count.to = '';
            this.getRowdyList();
        },

        getIpcCases(rowdy_id, case_type) {
            let vm = this;
            this.ipc = case_type;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getIpcCases', data: { rowdy_id: rowdy_id, case_type: case_type } })
                .then(response => {
                    loader.hide();
                    this.ipc_cases = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        editIpcCase(rowdy_id, rowdies_case_id) {
            this.$refs.ipc_modal_close.click();
            this.$router.push('/rowdy_case_update/' + rowdy_id + '/' + rowdies_case_id);
        },

        filterPoliceStation() {
            this.police_stations.forEach(ele => { ele.v_flag = true; });
            if (this.search_police_station == '') {
                return;
            }
            this.police_stations.forEach(ele => {
                if (!ele.police_station.toLowerCase().includes(this.search_police_station.toLowerCase())) {
                    ele.v_flag = false;
                };
            })
        },

        setRowdyImage(image) {
            this.rowdy_image = image;
        },

        getRowdyImages(rowdy_id) {
            let vm = this;
            let loader = vm.$loading.show();
            this.$store.dispatch('post', { uri: 'getRowdyImages', data: { rowdy_id: rowdy_id } })
                .then(response => {
                    loader.hide();
                    this.rowdy_images = response.data.data;
                    if (this.rowdy_images.length) {
                        this.shift.index = 0;
                        this.shift.image = this.rowdy_images[0].image;
                    }
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        shifLeft() {
            if (this.rowdy_images.length) {
                this.shift.index--;
                this.shift.image = this.rowdy_images[this.shift.index].image;
            }
        },

        shiftRight() {
            if (this.rowdy_images.length) {
                this.shift.index++;
                this.shift.image = this.rowdy_images[this.shift.index].image;
            }
        },


        PoliceStaionFilter(selected_police_stations) {
            let vm = this;
            vm.meta.police_station_ids = []
            selected_police_stations.map(function (ele) {
                vm.meta.police_station_ids.push(ele.police_station_id)
            })
            // vm.rowdy_list = [];
            vm.getRowdyList();
        },

        filterByCase() {
            this.rowdy_list = [];
            this.meta.page = 1;
            this.getRowdyList();
        },

        isNumber(e) {
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab', 'Enter'];
            const keyPressed = e.key;
            if (!keysAllowed.includes(keyPressed)) {
                e.preventDefault()
            }
        },

        getPendingProcesses(rowdy_id) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getPendingProcesses', data: { rowdy_id: rowdy_id } })
                .then(response => {
                    loader.hide();
                    this.pending_processes = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        editProcess(process_id, rowdy_id) {
            this.$refs.ipc_modal_close.click();
            this.$router.push('/rowdy_process_update/' + rowdy_id + '/' + process_id);
        },
        setPerPage(){
            this.meta.page = 1;
            this.getRowdyList();
        }
    }
}
</script>
<style scoped>
.card-one {
    border-width: 0px;
}

@media only screen and (max-width: 767px) {
    .gap-2 {
        margin-bottom: 0.5rem;
    }
}

.dropdown {
    position: relative;
    cursor: pointer;
}

.multiselect {
    position: relative;
}

.multiselect ul {
    border: 1px solid #ddd;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 8px 8px;
    top: -0.1rem;
    width: 100%;
    list-style: none;
    max-height: 150px;
    overflow: auto;
    background: white;
}

.overselect {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.multiselect {
    position: relative;
}

.multiselect ul {
    border: 1px solid #ddd;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 8px 8px;
    top: -0.1rem;
    width: 100%;
    list-style: none;
    max-height: 150px;
    overflow: auto;
    background: white;
}

thead {
    /* position: relative !important; */
    top: -2px !important;
    z-index: 5 !important;
    background-color: #f3f5f9
}
</style>
