<template>
    <div class="d-md-flex align-items-center justify-content-between gap-4 mt-2 mb-4">
        <div>
            <router-link :to="path" class="text-dark m-auto me-2" style="display: inline;"><i
                    class="ri-arrow-left-line fs-24"></i></router-link>
            <h3 v-if="update_status" style="display: inline;">Update PAR</h3>
            <h3 v-else style="display: inline;">Add PAR</h3>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="card-body">
                        <h5 class="main-title fw-semibold mb-0">Rowdy Name: {{ rowdy.rowdy_sheeter_name }}</h5>
                        <hr>
                        <div class="tab-pane active" id="gangDetails">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row g-3">
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Police Station</label>
                                            <span class="text-danger"> *</span>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getParCaseEditHistory('police_station')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <search :class="{ 'is-invalid': errors?.police_station_id }"
                                                :customClass="{ 'is-invalid': errors?.police_station_id }"
                                                :initialize="police_station_id" id="police_station_id"
                                                label="police_station" placeholder="Select police station"
                                                :data="police_stations"
                                                @selected="police_station => police_station_id = police_station">
                                            </search>
                                            <span v-if="errors.police_station_id" class="invalid-feedback">{{
                                                errors.police_station_id[0] }}</span>
                                        </div>
                                        <div v-if="isOthers" class="col-4">
                                            <label class="form-label">Other Police Station</label>
                                            <span class="text-danger"> *</span>
                                            <input class="form-control border-0 border-bottom" 
                                                v-model="par_case.other_police_station" type="text"
                                                :customClass="{ 'is-invalid': errors?.other_police_station }">
                                            <span v-if="errors.other_police_station" class="invalid-feedback">{{
                                                errors.other_police_station[0] }}</span>
                                        </div>
                                        <div class="mb-3 col-4">
                                            <label class="form-label">PAR No.</label>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getParCaseEditHistory('par_no')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <span class="text-danger"> *</span>
                                            <input class="form-control border-0 border-bottom" :class="{ 'is-invalid': errors.par_no }"
                                                type="text" v-model="par_case.par_no" placeholder="Enter PAR Number">
                                            <span v-if="errors.par_no" class="invalid-feedback">{{
                                                errors.par_no[0] }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Year</label>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getParCaseEditHistory('year')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <!-- <span class="text-danger"> *</span> -->
                                            <input class="form-control border-0 border-bottom" type="text" @keypress="isNumber($event)" maxlength="4"
                                                v-model="par_case.year" :class="{ 'is-invalid': errors.year }"
                                                placeholder="Enter 4 digit year">
                                            <span v-if="errors.year" class="invalid-feedback">{{
                                                errors.year[0] }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Section of Law</label>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getParCaseEditHistory('section_of_law')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <!-- <span class="text-danger">*</span> -->
                                            <input type="text" class="form-control border-0 border-bottom"
                                                v-model="par_case.section_of_law"
                                                placeholder="Enter IPC Sections..." />
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">PAR Date</label>
                                            <!-- <span class="text-danger">*</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getParCaseEditHistory('par_date')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="date"
                                                class="form-control form-control-sm border-0 border-bottom"
                                                :class="{ 'is-invalid': errors?.par_date }"
                                                v-model="par_case.par_date" />
                                            <span v-if="errors?.par_date" class="invalid-feedback">{{
                                                errors?.par_date[0]
                                            }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Bound Over From Date</label>
                                            <!-- <span class="text-danger">*</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getParCaseEditHistory('bound_over_from')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="date"
                                                class="form-control form-control-sm border-0 border-bottom"
                                                v-model="par_case.bound_over_from"
                                                :class="{ 'is-invalid': errors?.bound_over_from }" />
                                            <span v-if="errors?.bound_over_from" class="invalid-feedback">{{
                                                errors?.bound_over_from[0]
                                            }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Bound Over To Date</label>
                                            <!-- <span class="text-danger">*</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getParCaseEditHistory('bound_over_to')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="date"
                                                class="form-control form-control-sm border-0 border-bottom"
                                                v-model="par_case.bound_over_to"
                                                :class="{ 'is-invalid': errors?.bound_over_to }" />
                                            <span v-if="errors?.bound_over_to" class="invalid-feedback">{{
                                                errors?.bound_over_to[0]
                                            }}</span>
                                        </div>

                                        <div class="col-4 mb-3">
                                            <label class="form-label">Sent to JC / Forfeiture Date</label>
                                            <!-- <span class="text-danger">*</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getParCaseEditHistory('if_violated')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <select class="form-control border-0 border-bottom form-select"
                                                :class="{ 'is-invalid': errors?.if_voilated }"
                                                v-model="par_case.if_violated">
                                                <option value="">Select</option>
                                                <option value="Forfeiture">Forfeiture</option>
                                                <option value="Sent to JC">Sent to JC</option>
                                            </select>
                                            <span v-if="errors?.if_voilated" class="invalid-feedback">{{
                                                errors?.if_voilated[0] }}</span>
                                        </div>
                                        <div class="col-4 mb-3" v-if="par_case.if_violated">
                                            <label class="form-label">Violated Date</label>
                                            <span class="text-danger">*</span>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getParCaseEditHistory('violated_date')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="date"
                                                class="form-control form-control-sm border-0 border-bottom"
                                                v-model="par_case.violated_date"
                                                :class="{ 'is-invalid': errors?.violated_date }" />
                                            <span v-if="errors?.violated_date" class="invalid-feedback">{{
                                                errors?.violated_date[0]
                                            }}</span>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <label class="form-label">Surety Details</label>
                                            <!-- <span class="text-danger"> *</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getParCaseEditHistory('surety_details')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <textarea class="form-control form-control-sm border-0 border-bottom"
                                                :class="{ 'is-invalid': errors?.surety_details }"
                                                placeholder="Enter crime number..."
                                                v-model="par_case.surety_details" />
                                            <span v-if="errors?.surety_details" class="invalid-feedback">{{
                                                errors?.surety_details[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button v-if="update_status" class="btn btn-primary float-end"
                                        @click="updateParCase()">Update</button>
                                    <button v-else class="btn btn-primary float-end"
                                        @click="addParCase()">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- History information modal -->
    <div class="modal fade bd-example-modal-md" tabindex="-1" id="infoModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edited History</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table table-sm table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>NEW {{ edited_field_name }}</th>
                                    <th>OLD {{ edited_field_name }}</th>
                                    <th>Edited By</th>
                                    <th>Date Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="edited_history.length" v-for="edited, key in edited_history" :key="key">
                                    <td>{{ key + 1 }}</td>
                                    <td v-if="key > 0">{{ edited_history[key - 1].field_value }}</td>
                                    <td v-else>{{ new_record.field_value }}</td>
                                    <td>{{ edited.field_value }}</td>
                                    <td>{{ edited.edited_by.name }}</td>
                                    <td>{{ edited.edited_date_time }}</td>
                                </tr>
                                <tr v-else>
                                    <td colspan="5" class="text-center">No records to display!...</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
let Search = require("@/components/Search.vue").default;
export default {
    components: {
        Search
    },

    data() {
        return {
            police_station_id: '',
            par_case: {
                par_case_id: '',
                rowdy_id: '',
                police_station_id: '',
                other_police_station: '',
                par_no: '',
                year: '',
                section_of_law: '',
                par_date: '',
                bound_over_from: '',
                bound_over_to: '',
                surety_details: '',
                if_violated: '',
                violated_date: ''
            },
            police_stations: [],
            errors: [],
            rowdy: {
                rowdy_id: '',
                rowdy_sheeter_name: ''
            },
            update_status: false,
            edited_history: [],
            edited_field_name: '',
            nav_from: '',
            path: ''
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.nav_from = from.name;
            vm.rowdy.rowdy_id = to.params.rowdy_id;
            vm.getRowdy(to.params.rowdy_id);
            vm.getPoliceStations();
            if (to.name == 'ParUpdateCase') {
                vm.update_status = true;
                vm.getParCase(to.params.par_case_id);
            }
            vm.path = from.path;
        })
    },

    computed: {
        isOthers() {
            let police_station = this.police_stations.find(ele => {
                return ele.police_station_id == this.police_station_id;
            })

            if (police_station) {
                if (police_station.police_station == 'Others') {
                    return true;
                } else {
                    return false;
                }
            }
        }
    },

    methods: {
        getPoliceStations() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getPoliceStations' })
                .then(response => {
                    loader.hide();
                    this.police_stations = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.resposne.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getRowdy(rowdy_id) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getRowdy', data: { rowdy_id: rowdy_id } })
                .then(response => {
                    loader.hide();
                    this.rowdy = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.resposne.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },
        
        getParCase(par_case_id) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getParCase', data: { par_case_id: par_case_id } })
                .then(response => {
                    loader.hide();
                    this.par_case = response.data.data;
                    if(this.par_case.if_violated == null) this.par_case.if_violated = '';
                    this.police_station_id = this.par_case.police_station_id;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.resposne.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },


        addParCase() {
            let vm = this;
            this.par_case.police_station_id = this.police_station_id;
            this.par_case.rowdy_id = this.rowdy.rowdy_id;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'addParCase', data: this.par_case })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    if (this.nav_from == 'UpdateCases') {
                        this.$router.push('/update_cases_processes/' + this.par_case.rowdy_id);
                    } else {
                        // this.$router.push('/view_rowdy/' + this.par_case.rowdy_id);
                    }
                    this.errors = [];
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        updateParCase() {
            let vm = this;
            this.par_case.police_station_id = this.police_station_id;
            this.par_case.rowdy_id = this.rowdy.rowdy_id;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'updateParCase', data: this.par_case })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    this.par_case = response.data.par_case;
                    if(this.par_case.if_violated == null) this.par_case.if_violated = '';
                    this.errors = [];
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        isNumber(e) {
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            const keyPressed = e.key;
            if (!keysAllowed.includes(keyPressed)) {
                e.preventDefault()
            }
        },

        getParCaseEditHistory(field_name) {
            this.edited_field_name = field_name;
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getParCaseEditHistory', data: { par_case_id: this.par_case.par_case_id } })
                .then(response => {
                    loader.hide();
                    this.new_record = response.data.new;
                    this.new_record.field_value = this.new_record[field_name];
                    this.edited_history = response.data.history;
                    let temp = this.edited_history.filter(ele => {
                        ele.field_value = ele[field_name];
                        return ele[field_name] != null;
                    });
                    this.edited_history = temp;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },
    }
}
</script>