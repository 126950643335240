<template>
    <div class="d-md-flex align-items-center justify-content-between gap-4 mt-2 mb-4">
        <div v-if="update_status">
            <router-link :to="path" class="text-dark m-auto"><i class="ri-arrow-left-line fs-24"></i></router-link>
            <h2 class="main-title fs-28 mb-0 ms-1" style="display: inline;">Update User</h2>
        </div>
        <div v-else>
            <h2 class="main-title fs-28 mb-0">New User</h2>
        </div>
        <router-link v-can="'user.user_list'" to="/users_list"
            class="mt-auto btn btn-outline-primary d-flex align-items-center gap-2">
            <i class="ri-menu-line fs-18 lh-1"></i>All Users</router-link>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-md-flex align-items-center justify-content-between">
                        <div class="flex-fill">
                            <div class="row g-3">
                                <div class="col-6 mb-3">
                                    <label class="form-label">Office / Police Station</label>
                                    <span class="text-danger">*</span>
                                    <select class="form-control border-0 border-bottom form-select" v-model="is_office"
                                        @change="onOfficeSelect()">
                                        <option value="">Select</option>
                                        <option value="office">Office</option>
                                        <option value="police_station">Police Station</option>
                                    </select>
                                </div>
                                <div class="col-6 mb-3" v-if="is_office == 'office'">
                                    <label class="form-label">Division</label>
                                    <span class="text-danger">*</span>
                                    <select name="" id="" class="form-control border-0 border-bottom form-select"
                                        :class="{ 'is-invalid': errors.role_id }" v-model="user.division_id"
                                        @change="getSubDivisions()">
                                        <option value="">Select</option>
                                        <option v-for="division, key in divisions" :value="division.division_id">{{
                                            division.division }}</option>
                                    </select>
                                    <span v-if="errors.division_id" class="invalid-feedback">{{ errors.division_id[0]
                                        }}</span>
                                </div>
                                <div class="col-6 mb-3" v-if="is_office == 'office'">
                                    <label class="form-label">Sub Division</label>
                                    <select name="" id="" class="form-control border-0 border-bottom form-select" @change="setEmailAddress()"
                                        :class="{ 'is-invalid': errors.sub_division_id }"
                                        v-model="user.sub_division_id">
                                        <option value="">Select</option>
                                        <option v-for="sub_division, key in sub_divisions"
                                            :value="sub_division.sub_division_id">{{ sub_division.sub_division }}
                                        </option>
                                    </select>
                                    <span v-if="errors.sub_division_id" class="invalid-feedback">{{
                                        errors.sub_division_id[0]
                                    }}</span>
                                </div>
                                <div class="col-6 mb-3" v-show="is_office == 'police_station'">
                                    <label class="form-label">Police Station / Name</label>
                                    <span class="text-danger">*</span>
                                    <search :class="{ 'is-invalid': errors?.police_station_id }"
                                        class="bord er-0 border-bottom"
                                        :customClass="{ 'is-invalid': errors?.police_station_id }"
                                        :initialize="police_station_id" id="police_station_id" label="police_station"
                                        placeholder="Police station" :data="police_stations"
                                        @selected="assignPoliceStation">
                                    </search>
                                    <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0]
                                        }}</span>
                                </div>
                                <div class="col-6 mb-3">
                                    <label class="form-label">E - Mail</label>
                                    <span class="text-danger">*</span>
                                    <input type="email" class="form-control border-0 border-bottom"
                                        :class="{ 'is-invalid': errors.email }" placeholder="Enter user email"
                                        v-model="user.email" />
                                    <span v-if="errors.email" class="invalid-feedback">{{
                                        errors.email[0] }}</span>
                                </div>
                                <div class="col-6 mb-3">
                                    <label class="form-label">Mobile</label>
                                    <span class="text-danger">*</span>
                                    <input type="text" class="form-control border-0 border-bottom"
                                        :class="{ 'is-invalid': errors.mobile_no }"
                                        placeholder="Enter user mobile number..." v-model="user.mobile_no"
                                        @keypress="isNumber($event)" maxlength="10" />
                                    <span v-if="errors.mobile_no" class="invalid-feedback">{{
                                        errors.mobile_no[0] }}</span>
                                </div>
                                <div class="col-6 mb-3">
                                    <label class="form-label">Designation</label>
                                    <select name="" id="" class="form-control border-0 border-bottom form-select"
                                        :class="{ 'is-invalid': errors.designation_id }" v-model="user.designation_id">
                                        <option value="">Select</option>
                                        <option v-for="designation, key in designations"
                                            :value="designation.designation_id">{{ designation.designation }}</option>
                                    </select>
                                    <span v-if="errors.designation_id" class="invalid-feedback">{{
                                        errors.designation_id[0]
                                    }}</span>
                                </div>
                                <div class="col-6 mb-3">
                                    <label class="form-label">Role</label>
                                    <span class="text-danger">*</span>
                                    <select name="" id="" class="form-control border-0 border-bottom form-select"
                                        :class="{ 'is-invalid': errors.role_id }" v-model="user.role_id">
                                        <option value="">Select</option>
                                        <option v-for="role, key in roles" :value="role.role_id">{{ role.role }}
                                        </option>
                                    </select>
                                    <span v-if="errors.role_id" class="invalid-feedback">{{ errors.role_id[0]
                                        }}</span>
                                </div>
                                <div class="col-12">
                                    <label class="form-label">Address</label>
                                    <textarea name="" v-model="user.address" class="form-control border-0 border-bottom"
                                        placeholder="Enter Address" :class="{ 'is-invalid': errors.address }" id=""
                                        cols="30" rows="1"></textarea>
                                    <span v-if="errors.address" class="invalid-feedback">{{ errors.address[0]
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-12">
                            <button v-if="update_status" class="btn btn-primary float-end" @click="updateUser()">Update
                                User</button>
                            <button v-else class="btn btn-primary float-end" @click="addUser()">Add User</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
let Search = require("@/components/Search.vue").default;
export default {
    components: {
        Search
    },
    data() {
        return {
            user: {
                user_id: '',
                police_station_id: '',
                division_id: '',
                sub_division_id: '',
                name: '',
                email: '',
                password: '1qaz2wsx',
                mobile_no: '',
                designation_id: '',
                role_id: '',
                address: '',
                avatar: '/assets/img/user.png'
            },
            errors: [],
            image: '',
            update_status: false,
            path: '',
            police_stations: [],
            police_station_id: '',
            roles: [],
            is_office: '',
            divisions: [],
            sub_divisions: [],
            designations: [],
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.getRoles();
            vm.getDesignations();
            vm.getPoliceStations();
            vm.path = from.path;
            if (to.name == 'UserUpdate') {
                vm.update_status = true;
                vm.user.user_id = to.params.user_id;
                vm.getUser();
            }
        })
    },

    methods: {
        addUser() {
            if (this.police_station_id) {
                this.user.name = this.police_stations.find(ele => { return ele.police_station_id == this.police_station_id }).police_station;
                this.user.division_id = '';
                this.user.sub_division_id = '';
            }
            if (this.user.division_id) {
                this.user.name = this.divisions.find(ele => { return ele.division_id == this.user.division_id }).division;
            }
            if (this.user.sub_division_id) {
                this.user.name = this.sub_divisions.find(ele => { return ele.sub_division_id == this.user.sub_division_id }).sub_division;
            }

            let data = new FormData();
            data.append('name', this.user.name);
            data.append('email', this.user.email);
            data.append('password', this.user.password);
            data.append('mobile_no', this.user.mobile_no);
            data.append('role_id', this.user.role_id);
            data.append('address', this.user.address);
            data.append('avatar', this.image);
            data.append('police_station_id', this.police_station_id);
            data.append('division_id', this.user.division_id);
            data.append('sub_division_id', this.user.sub_division_id);
            data.append('designation_id', this.user.designation_id);

            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'addUser', data: data })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    this.errors = [];
                    this.$router.push('/users_list');
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        updateUser() {
            if (this.police_station_id) {
                this.user.name = this.police_stations.find(ele => { return ele.police_station_id == this.police_station_id }).police_station;
            }
            if (this.user.division_id) {
                this.user.name = this.divisions.find(ele => { return ele.division_id == this.user.division_id }).division;
            }
            if (this.user.sub_division_id) {
                this.user.name = this.sub_divisions.find(ele => { return ele.sub_division_id == this.user.sub_division_id }).sub_division;
            }

            let data = new FormData();
            data.append('user_id', this.user.user_id);
            data.append('name', this.user.name);
            data.append('email', this.user.email);
            data.append('password', this.user.password);
            data.append('mobile_no', this.user.mobile_no);
            data.append('role_id', this.user.role_id);
            data.append('address', this.user.address);
            data.append('avatar', this.image);
            data.append('police_station_id', this.police_station_id);
            data.append('division_id', this.user.division_id);
            data.append('sub_division_id', this.user.sub_division_id);
            data.append('designation_id', this.user.designation_id);
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'updateUser', data: data })
                .then(response => {
                    loader.hide();
                    this.user = response.data.data;
                    this.$store.dispatch('success', response.data.message);
                    this.errors = [];
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        onImageChange(e) {
            let vm = this;
            vm.image = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(vm.image);
            reader.onload = (e) => {
                vm.user.avatar = e.target.result;
            };
        },

        getUser() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getUser', data: this.user })
                .then(response => {
                    loader.hide();
                    this.user = response.data.data;
                    this.police_station_id = this.user.police_station_id;
                    this.is_office = this.user.office_type;
                    this.getDivisions();
                    this.getSubDivisions();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getPoliceStations() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getPoliceStations' })
                .then(response => {
                    loader.hide();
                    let temp = response.data.data;
                    this.police_stations = temp.filter(ele => ele.police_station != 'Others');
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getDivisions() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getDivisions' })
                .then(response => {
                    loader.hide();
                    this.divisions = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getSubDivisions() {
            if(this.user.address == ''){
                this.user.address = this.divisions.find(ele => {return ele.division_id == this.user.division_id}).address;                
            }
            if(this.user.email == ''){
                this.user.email = this.divisions.find(ele => {return ele.division_id == this.user.division_id}).email;
            }
            // this.user.police_station_id = this.police_station_id = '';
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getSubDivisions', data: { division_id: this.user.division_id } })
                .then(response => {
                    loader.hide();
                    this.sub_divisions = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        assignPoliceStation(police_station_id) {
            this.police_station_id = police_station_id;
            let police_station = this.police_stations.find(ele => ele.police_station_id === this.police_station_id);
            this.user.email = police_station.email;
            this.user.name = police_station.police_station;
        },

        isNumber(e) {
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            const keyPressed = e.key;
            if (!keysAllowed.includes(keyPressed)) {
                e.preventDefault()
            }
        },

        getRoles() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getRoles' })
                .then(response => {
                    loader.hide();
                    this.roles = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getDesignations() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getDesignations' })
                .then(response => {
                    loader.hide();
                    this.designations = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        onOfficeSelect() {
            if (this.is_office == 'office') {
                this.getDivisions();
            } else {
                this.getPoliceStations();
            }
        },

        setEmailAddress(){
            this.user.email = this.sub_divisions.find(ele => { return ele.sub_division_id == this.user.sub_division_id}).email;
            this.user.address = this.sub_divisions.find(ele => { return ele.sub_division_id == this.user.sub_division_id}).address;
        },
    }
}
</script>