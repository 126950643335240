<template>
    <div class="d-md-flex align-items-center justify-content-between gap-4 mt-2 mb-4">
        <div>
            <router-link :to="path" class="text-dark m-auto me-2" style="display: inline;"><i
                    class="ri-arrow-left-line fs-24"></i></router-link>
            <h3 v-if="update_status" style="display: inline;">Update Goonda Act / Externment / KCOCA Act</h3>
            <h3 v-else style="display: inline;">Add Goonda Act / Externment / KCOCA Act</h3>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="card-body">
                        <h5 class="main-title fw-semibold mb-0">Rowdy Name: {{ rowdy.rowdy_sheeter_name }}</h5>
                        <hr>
                        <div class="tab-pane active" id="gangDetails">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row g-3">
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Type</label>
                                            <span class="text-danger"> *</span>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getGoondaEditHistory('type')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <select class="form-control border-0 border-bottom form-select"
                                                :class="{ 'is-invalid': errors.type }" v-model="rowdy_goonda.type"
                                                @change="selectCaseType(rowdy_goonda.type)">
                                                <option value="">Select</option>
                                                <option value="Goonda">Goonda</option>
                                                <option value="Externment">Externment</option>
                                                <option value="KCOCA_Act">KCOCA Act</option>
                                            </select>
                                            <span v-if="errors.type" class="invalid-feedback">{{ errors.type[0]
                                                }}</span>
                                        </div>
                                        <div v-show="externment || goonda || kcoca_act" class="col-4 mb-3">
                                            <label class="form-label">Police Station</label>
                                            <span class="text-danger"> *</span>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getGoondaEditHistory('police_station')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <search :class="{ 'is-invalid': errors?.police_station_id }"
                                                :customClass="{ 'is-invalid': errors?.police_station_id }"
                                                :initialize="police_station_id" id="police_station_id"
                                                label="police_station" placeholder="Select police station"
                                                :data="police_stations"
                                                @selected="police_station => police_station_id = police_station">
                                            </search>
                                            <span v-if="errors.police_station_id" class="invalid-feedback">{{
                                                errors.police_station_id[0] }}</span>
                                        </div>
                                        <div v-show="externment || goonda || kcoca_act" class="col-4 mb-3">
                                            <label class="form-label">Order No. and Date</label>
                                            <!-- <span class="text-danger"> *</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getGoondaEditHistory('order_no')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="text" class="form-control border-0 border-bottom"
                                                :class="{ 'is-invalid': errors.order_no }" v-model="rowdy_goonda.order_no"
                                                placeholder="Enter order no. and date..." />
                                            <span v-if="errors.order_no" class="invalid-feedback">{{ errors.order_no[0]
                                                }}</span>
                                        </div>
                                        <div v-show="externment || goonda"  class="mb-3"
                                            :class="{ 'col-4': rowdy_goonda.type == 'Goonda', 'col-6': (rowdy_goonda.type == 'Externment' || rowdy_goonda.type == '') }">
                                            <label class="form-label">Person Name</label>
                                            <!-- <span class="text-danger"> *</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getGoondaEditHistory('person_name')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="text" class="form-control border-0 border-bottom"
                                                :class="{ 'is-invalid': errors.person_name }"
                                                v-model="rowdy_goonda.person_name" placeholder="Enter person name..." />
                                            <span v-if="errors.person_name" class="invalid-feedback">{{
                                                errors.person_name[0] }}</span>
                                        </div>
                                        <div class="mb-3" v-show="externment || goonda"
                                            :class="{ 'col-4': rowdy_goonda.type == 'Goonda', 'col-6': (rowdy_goonda.type == 'Externment' || rowdy_goonda.type == '') }">
                                            <label class="form-label">Father Name</label>
                                            <!-- <span class="text-danger"> *</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getGoondaEditHistory('father_name')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="text" class="form-control border-0 border-bottom"
                                                :class="{ 'is-invalid': errors.father_name }"
                                                v-model="rowdy_goonda.father_name" placeholder="Enter father name..." />
                                            <span v-if="errors.father_name" class="invalid-feedback">{{
                                                errors.father_name[0] }}</span>
                                        </div>
                                        <div v-show="goonda" class="col-4 mb-3">
                                            <label class="form-label">Approved Status</label>
                                            <span class="text-danger"> *</span>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getGoondaEditHistory('approved_status')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <select class="form-control border-0 border-bottom form-select"
                                                :class="{ 'is-invalid': errors.approved_status }"
                                                v-model="rowdy_goonda.approved_status">
                                                <option value="">Select</option>
                                                <option value="Approved">Approved</option>
                                                <option value="Rejected">Rejected</option>
                                            </select>
                                            <span v-if="errors.approved_status" class="invalid-feedback">{{
                                                errors.approved_status[0] }}</span>
                                        </div>
                                        <div v-show="externment || goonda" class="col-12 mb-3">
                                            <label class="form-label">Address</label>
                                            <!-- <span class="text-danger"> *</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getGoondaEditHistory('address')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <textarea class="form-control border-0 border-bottom"
                                                :class="{ 'is-invalid': errors.address }" v-model="rowdy_goonda.address"
                                                placeholder="Enter address..." />
                                            <span v-if="errors.address" class="invalid-feedback">{{ errors.address[0]
                                                }}</span>
                                        </div>
                                        <div v-show="externment || goonda || kcoca_act" class="col-12 mb-3">
                                            <label class="form-label">Activities/Remarks</label>
                                            <!-- <span class="text-danger"> *</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getGoondaEditHistory('activities')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <textarea class="form-control border-0 border-bottom"
                                                :class="{ 'is-invalid': errors.activities }"
                                                v-model="rowdy_goonda.activities" placeholder="Enter activities..." />
                                            <span v-if="errors.activities" class="invalid-feedback">{{
                                                errors.activities[0] }}</span>
                                        </div>
                                        <div v-show="externment" class="col-12 mb-3">
                                            <label class="form-label">To Which Place</label>
                                            <!-- <span class="text-danger"> *</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getGoondaEditHistory('which_place')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <textarea class="form-control border-0 border-bottom"
                                                :class="{ 'is-invalid': errors.which_place }"
                                                v-model="rowdy_goonda.which_place" placeholder="Enter which place..." />
                                            <span v-if="errors.which_place" class="invalid-feedback">{{
                                                errors.which_place[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button v-if="update_status" class="btn btn-primary float-end"
                                        @click="updateGoonda()">Update</button>
                                    <button v-else class="btn btn-primary float-end"
                                        @click="addGoonda()">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- History information modal -->
    <div class="modal fade bd-example-modal-md" tabindex="-1" id="infoModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edited History</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table table-sm table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>NEW {{ edited_field_name }}</th>
                                    <th>OLD {{ edited_field_name }}</th>
                                    <th>Edited By</th>
                                    <th>Date Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="edited_history.length" v-for="edited, key in edited_history" :key="key">
                                    <td>{{ key + 1 }}</td>
                                    <td v-if="key > 0">{{ edited_history[key - 1].field_value }}</td>
                                    <td v-else>{{ new_record.field_value }}</td>
                                    <td>{{ edited.field_value }}</td>
                                    <td>{{ edited.edited_by.name }}</td>
                                    <td>{{ edited.edited_date_time }}</td>
                                </tr>
                                <tr v-else>
                                    <td colspan="5" class="text-center">No records to display!...</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
let Search = require("@/components/Search.vue").default;
export default {
    components: {
        Search
    },
    data() {
        return {
            police_station_id: '',
            rowdy_goonda: {
                goonda_id: '',
                rowdy_id: '',
                type: '',
                police_station_id: '',
                person_name: '',
                father_name: '',
                approved_status: '',
                which_place: '',
                address: '',
                activities: '',
                order_no: ''
            },
            police_stations: [],
            errors: [],
            rowdy: {
                rowdy_sheeter_name: ''
            },
            update_status: false,
            edited_history: [],
            new_record: '',
            edited_field_name: '',
            nav_from: '',
            goonda: false,
            externment: false,
            kcoca_act: false,
            path:''
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.nav_from = from.name;
            if (to.name == 'RowdyGoondaUpdate') {
                vm.update_status = true;
                vm.getGoonda(to.params.goonda_id);
            }
            vm.rowdy_goonda.rowdy_id = to.params.rowdy_id;
            vm.getRowdy(to.params.rowdy_id);
            vm.getPoliceStations();
            vm.path = from.path;
        })
    },

    methods: {
        getPoliceStations() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getPoliceStations' })
                .then(response => {
                    loader.hide();
                    this.police_stations = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.resposne.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getRowdy(rowdy_id) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getRowdy', data: { rowdy_id: rowdy_id } })
                .then(response => {
                    loader.hide();
                    this.rowdy = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.resposne.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        addGoonda() {
            let vm = this;
            this.rowdy_goonda.police_station_id = this.police_station_id;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'addGoonda', data: this.rowdy_goonda })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    if(this.nav_from == 'UpdateCases'){
                        this.$router.push('/update_cases_processes/' + this.rowdy_goonda.rowdy_id);
                    } else {
                        this.$router.push('/update_cases_processes/' + this.rowdy_goonda.rowdy_id);
                    } 
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        updateGoonda() {
            let vm = this;
            this.rowdy_goonda.police_station_id = this.police_station_id;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'updateGoonda', data: this.rowdy_goonda })
                .then(response => {
                    loader.hide();
                    this.update_status = false;
                    this.$store.dispatch('success', response.data.message);
                    if(this.nav_from == 'UpdateCases'){
                        this.$router.push('/update_cases_processes/' + this.rowdy_goonda.rowdy_id);
                    } else {
                        this.$router.push('/view_rowdy/' + this.rowdy_goonda.rowdy_id);
                    } 
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getGoonda(goonda_id) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getGoonda', data: { goonda_id: goonda_id } })
                .then(response => {
                    loader.hide();
                    this.rowdy_goonda = response.data.data;
                    this.police_station_id = this.rowdy_goonda.police_station_id;
                    this.selectCaseType(this.rowdy_goonda.type);
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.resposne.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getGoondaEditHistory(field_name) {
            this.edited_field_name = field_name;
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getGoondaEditHistory', data: { goonda_id: this.rowdy_goonda.goonda_id } })
                .then(response => {
                    loader.hide();
                    this.new_record = response.data.new;
                    this.new_record.field_value = this.new_record[field_name];
                    this.edited_history = response.data.history;
                    let temp = this.edited_history.filter(ele => {
                        ele.field_value = ele[field_name];
                        return ele[field_name] != null;
                    });
                    this.edited_history = temp;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        selectCaseType(type){
            this.goonda = false;
            this.externment = false;
            this.kcoca_act = false;
            switch (type) {
                case 'Goonda':
                    this.goonda = true;
                    break;
                case 'Externment':
                    this.externment = true;
                    break;
                case 'KCOCA_Act':
                    this.kcoca_act = true;
                    break;
            
                default:
                    break;
            }
        }
    }
}
</script>