<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0">Minor Head</h4>
        </div>
    </div>
    <div class="row g-3 mb-2">
        <div class="col-xl-4">
            <form class="card card-one" @submit.prevent="submitForm()">
                <div class="card-header">
                    <h6 class="card-title" v-if="status">New Minor Head </h6>
                    <h6 class="card-title" v-else>Update Minor Head </h6>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 mb-2">
                            <label class="form-label">Major Head</label><span class="text-danger"> *</span>
                            <select class="form-select" :class="{'is-invalid': errors.major_head_id}" v-model="minor_head.major_head_id" ref="major_head_id">
                                <option value="">Select Major Head</option>
                                <option v-for="major_head,key in major_heads" :key="key" :value="major_head.major_head_id">{{ major_head.major_head }}</option>
                            </select>
                            <span v-if="errors.major_head_id" class="invalid-feedback">{{ errors.major_head_id[0] }}</span>
                        </div>
                        <div class="col-md-12">
                            <label class="form-label">Minor Head</label><span class="text-danger"> *</span>
                            <input type="text" class="form-control" ref="minor_head_id" placeholder="Minor Head" :class="{'is-invalid': errors.minor_head}" v-model="minor_head.minor_head" />
                            <span v-if="errors.minor_head" class="invalid-feedback">{{ errors.minor_head[0] }}</span>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-end">
                    <button class="btn btn-danger me-2" type="button" @click="discard()"><i class="ri-prohibited-line me-1"></i>Discard</button>
                    <button class="btn btn-primary " type="submit">
                        <span v-if="status"> <i class="ri-save-line me-1"></i> Submit</span>
                        <span v-else> <i class="ri-save-line me-1"></i> Update </span>
                    </button>
                </div>
            </form>
        </div>
        <div class="col-xl-8">
            <div class="card card-one">
                <div class="card-header">
                    <h6 class="card-title">Minor Heads</h6>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <input type="text" class="form-control mb-2" id="search" placeholder="Search" v-model="meta.search" @keypress.enter="search()">
                    </div>
                    <div class="table-responsive">
                        <table class="table text-nowrap table-bordered table-hover table-bottom-border mb-0">
                            <thead>
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center">Sl No.</th>
                                    <th @click="sort('major_head_id')">
                                        Major Head
                                        <span>
                                            <i v-if="meta.keyword=='major_head_id' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword=='major_head_id' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('minor_head_id')">
                                        Minor Head
                                        <span>
                                            <i v-if="meta.keyword=='minor_head_id' && meta.order_by=='asc'" class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword=='minor_head_id' && meta.order_by=='desc'" class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="minor_head, key in minor_heads" :key="key">
                                    <td class="text-center">{{ meta.from + key }}</td>
                                    <td>{{minor_head.major_head?.major_head}}</td>
                                    <td>{{minor_head.minor_head}}</td>

                                    <td class="text-center">
                                        <div class="form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" :id="'minor_head' + minor_head.minor_head_id" :checked="minor_head.status" :value="minor_head.status" @change="deleteMinorHead(minor_head)" />
                                            <label class="custom-control-label" :for="'minor_head' + minor_head.minor_head_id"></label>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <nav>
                                            <a href="javascript:void(0)" class="text-primary me-2" @click="edit(minor_head)"><i class="ri-edit-line fs-18 lh-1"></i></a>
                                        </nav>
                                    </td>
                                </tr>
                                <tr v-if="minor_heads.length <= 0">
                                    <td colspan="4" style="text-align: center;">No Records Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: {
            Pagination,
        },
        data() {
            return {
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "minor_head_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
                status:true,
                minor_head:{
                    minor_head_id:"",
                    major_head_id:"",
                    minor_head:"",
                    status:""
                },
                minor_heads:[],
                major_heads:[],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.$refs.major_head_id.focus();
            vm.index();
            vm.getMajorHeads();
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },
            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateMinorHeads", data: vm.meta })
                .then(function (response) {
                    vm.minor_heads = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            create(){
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addMinorHead", data: vm.minor_head };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Minor head is successfully created");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            edit(minor_head){
                let vm = this;
                vm.minor_head = minor_head;
                vm.status = false;
                vm.$refs.minor_head_id.focus();
            },
            update(){
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateMinorHead", data: vm.minor_head };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Minor head is successfully updated");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            deleteMinorHead(minor_head){
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "deleteMinorHead", data: minor_head };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", response.data.message);
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getMajorHeads(){
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "getMajorHeads" };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.major_heads = response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            discard() {
                let vm = this;
                vm.minor_head.minor_head = "";
                vm.minor_head.major_head_id = "";
                vm.minor_head.minor_head_id = "";
                vm.errors = [];
                vm.status = true;
                vm.$refs.minor_head_id.focus();
                vm.index();
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            search(){
               let vm = this;
               vm.meta.page = 1;
               vm.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
        },
    };
</script>
<style scoped>
 input:focus,
    select:focus {
        outline: none;
        box-shadow: 0 0 0 0 transparent;
    }
</style>