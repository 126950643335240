<template>
    <div class="d-md-flex align-items-center justify-content-between mb-4">
        <div>
            <div class="d-sm-flex p-2 p-sm-4 p-md-2 p-xl-2 gap-2 align-items-center">
                <router-link v-if="path != '' || path != '/'" :to="path" class="text-dark"><i
                        class="ri-arrow-left-line fs-24"></i></router-link>
                <div class="avatar"><span class="fs-24 fw-600 avatar-initial bg-light-primary">{{
                    gang?.gang_name.charAt(0) }}G</span></div>
                <div class="review-body">
                    <h6 class="text-dark fs-20 mb-0 fw-600">{{ gang?.gang_name }}</h6>
                    <div class="d-flex align-items-center gap-1 text-dark mt-1">
                        <small><i class="ri-map-pin-fill me-1"></i>{{ gang?.leader?.rowdy?.present_address }}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex gap-3 mt-3 mt-md-0">
            <div class="form-check form-switch my-auto">
                <input class="form-check-input" type="checkbox" v-model="opposite_gang_toggle"
                    id="flexSwitchCheckChecked" @change="getOppositeGangs()" />
                <label class="form-check-label fs-16 text-primary fw-500" for="flexSwitchCheckChecked">Opposite
                    Gang</label>
            </div>
        </div>
    </div>
    <div v-if="opposite_gang_toggle">
        <div class=" mb-3">
            <h4 class="main-title mb-0">Opposite Gangs</h4>
        </div>
        <div class="row g-3 mb-2">
            <div class="col-sm-4" v-for="gang, key in opposite_gangs">
                <a href="javascript:void(0)" @click="setGang(gang.gang_id)">
                    <div class="card card-one" :class="{ 'border-primary': display_gang.gang_id == gang.gang_id }">
                        <div class="card-body p-3">
                            <div class="d-flex align-items-center gap-2 mb-2">
                                <div class="avatar" style="width: 40%;">
                                    <span class="avatar-initial bg-primary">{{ gang.gang_name.charAt(0)
                                        }}G</span>
                                </div>
                                <div class="review-body">
                                    <h6 class="text-dark mb-0">{{ gang.gang_name }}</h6>
                                    <div class="d-flex align-items-center gap-1 text-primary">
                                        <small class="text-dark">{{ gang.leader?.rowdy?.present_address }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-row-reverse">
                                <div class="mutual-badge rounded-pill badge bg-light-blue">
                                <!-- <ul> -->
                                    <!-- <li v-for="associate, key in gang.associates">
                                            <a href="javascript:void(0)"  class="avatar"><img :src="associate?.rowdy?.rowdy_sheeter_photo"
                                                    alt=""></a>       
                                        </li> -->
                                    <template v-if="gang.associates.length < 4">
                                        <ul>
                                            <li v-for="associate, key in gang.associates">
                                                <a href="" class="avatar"><img
                                                        :src="associate?.rowdy?.rowdy_sheeter_photo" alt="" />
                                                </a>
                                            </li>
                                        </ul>
                                        <label v-if="gang.associates.length == 1" class="text-dark">{{
                                            gang.associates.length }}
                                            Member</label>
                                        <label v-else class="text-dark">{{ gang.associates.length }}
                                            Members</label>
                                    </template>
                                    <template v-else>
                                        <ul>
                                            <li v-for="i in 3">
                                                <a href="" class="avatar"><img
                                                        :src="gang.associates[i].rowdy.rowdy_sheeter_photo" alt="" />
                                                </a>
                                            </li>
                                        </ul>
                                        <label class="text-dark">+ {{ gang.associates.length - 3 }} = {{
                                            gang.associates.length }} Members</label>
                                    </template>
                                <!-- </ul> -->
                                <!-- <label>{{ gang?.associates?.length }}</label> -->
                            </div>
                            </div>                            
                        </div><!-- card-body -->
                    </div><!-- card -->
                </a>
            </div>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-5">
            <div class="card card-one">
                <div class="card-body">
                    <div class="d-sm-flex p-2 p-sm-4 p-md-2 p-xl-2 mb-1">
                        <div id="cAvatar" class="me-4 mb-3 mb-sm-0">
                            <a data-bs-toggle="modal" data-bs-target="#imageSlideModal">
                                <div class="avatar">
                                    <img class="leader-img"
                                        :src="display_gang.leader ? display_gang?.leader?.rowdy?.rowdy_sheeter_photo : '/assets/img/user.png'"
                                        alt="" />
                                </div>
                            </a>
                        </div>
                        <div class="flex-fill">
                            <span class="badge rounded-pill bg-light-secondary">GANG LEADER</span>
                            <h5 id="cName" class="mb-0 fs-20 text-dark fw-semibold">{{
                                display_gang.leader?.rowdy?.rowdy_sheeter_name }}</h5>
                            <p class="address">{{ display_gang.leader?.rowdy?.present_address }}</p>
                        </div>
                    </div>
                    <div class="row g-3 text-center border-top">
                        <div class="col-sm-4 mt-2 border-right" data-bs-toggle="modal" data-bs-target="#casesModal"
                            @click="getCases(display_gang.leader.rowdy_id)">
                            <h3 class="mb-0 fs-18 fw-500">{{ display_gang.leader?.rowdy?.no_of_cases }}</h3>
                            <p class="card-title case-secondary mb-2">Cases</p>
                        </div>
                        <div class="col-sm-4 mt-2 border-right" data-bs-toggle="modal" data-bs-target="#processModal"
                            @click="getProcesses(display_gang.leader.rowdy_id)">
                            <h3 class="mb-0 fs-18 fw-500 text-dark">{{ display_gang.leader?.rowdy?.process_count }}
                            </h3>
                            <p class="card-title case-secondary mb-2">Process</p>
                        </div>
                        <div class="col-sm-4 mt-2" data-bs-toggle="modal" data-bs-target="#goondaModal"
                            @click="getGoondas(display_gang.leader.rowdy_id)">
                            <h3 class="mb-0 fs-18 fw-500 text-dark">{{
                                display_gang.leader?.rowdy?.goonda_externment_count }}
                            </h3>
                            <p class="card-title case-secondary mb-2">G Act/Ext</p>
                        </div>
                        <div class="col-sm-12">
                            <button v-if="display_gang.leader" type="button"
                                class="btn m-auto btn-outline-primary d-flex align-items-center gap-2"
                                @click="viewProfile(display_gang.leader.rowdy_id)"><i
                                    class="ri-eye-line fs-18 lh-1"></i>View
                                Profile</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-7">
            <div class="card card-one py-4">
                <div class="card-body">
                    <ul class="nav nav-tabs card-header-tabs mb-0 justify-content-center m-auto nav-tab-border"
                        data-bs-tabs="tabs">
                        <li class="nav-item nav_bg">
                            <a class="nav-link text-dark active" aria-current="true" data-bs-toggle="tab"
                                href="#cases">Cases</a>
                        </li>
                        <li class="nav-item nav_bg">
                            <a class="nav-link text-dark" data-bs-toggle="tab" href="#process">Process</a>
                        </li>
                        <li class="nav-item nav_bg">
                            <a class="nav-link text-dark" data-bs-toggle="tab" href="#goonda">Goonda Act /
                                Externment</a>
                        </li>
                    </ul>
                    <form class="card-body tab-content pt-0">
                        <div class="tab-pane active" id="cases">
                            <!-- <div class="row mt-2 g-3 text-center border-top1"> -->
                            <div class="row mt-2 row-cols-auto g-3 g-xl-4 gap-2 pt-2 text-dark justify-content-center">
                                <div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#ipcModal"
                                    @click="getIpcCases(display_gang.leader.rowdy_id, 'MURDER')">
                                    <h3 class="fs-32 mb-0  text-center ">{{
                                        display_gang.leader?.rowdy?.count_murder_cases
                                    }}
                                    </h3>
                                    <label class="card-title fw-400 mb-2">MURDER</label>
                                </div>
                                <div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#ipcModal"
                                    @click="getIpcCases(display_gang.leader.rowdy_id, 'ATTEMPT_TO_MURDER')">
                                    <h3 class="fs-32 mb-0 text-center">{{
                                        display_gang.leader?.rowdy?.count_attempt_to_murder_cases }}</h3>
                                    <label class="card-title fw-400 mb-2">ATTEMPT TO<br>MURDER</label>
                                </div>
                                <div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#ipcModal"
                                    @click="getIpcCases(display_gang.leader.rowdy_id, '149')">
                                    <h3 class="fs-32 mb-0 text-center">{{ display_gang.leader?.rowdy?.cases_149 }}</h3>
                                    <label class="card-title fw-400 mb-2">149 IPC</label>
                                </div>
                                <div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#ipcModal"
                                    @click="getIpcCases(display_gang.leader.rowdy_id, '420')">
                                    <h3 class="fs-32 mb-0 text-center">{{ display_gang.leader?.rowdy?.cases_420 }}</h3>
                                    <label class="card-title fw-400 mb-2">420 IPC</label>
                                </div>
                                <div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#ipcModal"
                                    @click="getIpcCases(display_gang.leader.rowdy_id, 'Robbery')">
                                    <h3 class="fs-32 mb-0 text-center">{{ display_gang.leader?.rowdy?.cases_robbery }}
                                    </h3>
                                    <label class="card-title fw-400 mb-2">Robbery</label>
                                </div>
                                <div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#ipcModal"
                                    @click="getIpcCases(display_gang.leader.rowdy_id, 'others')">
                                    <h3 class="fs-32 mb-0 text-center">{{ display_gang.leader?.rowdy?.others }}
                                    </h3>
                                    <label class="card-title fw-400 mb-2">Others</label>
                                </div>
                                <div class="col mt-2" data-bs-toggle="modal" data-bs-target="#casesModal"
                                    @click="getCases(display_gang.leader.rowdy_id)">
                                    <h3 class="fs-32 mb-0 text-center">{{ display_gang.leader?.rowdy?.cases_type_total
                                        }}</h3>
                                    <label class="card-title fw-400 mb-2">Total</label>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="process">
                            <div class="row mt-2 row-cols-auto g-3 g-xl-4 gap-2 pt-2 text-dark justify-content-center">
                                <div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#processModal"
                                    @click="getProcesses(display_gang.leader.rowdy_id, 'notices')">
                                    <h3 class="mb-0 text-center">{{ display_gang.leader?.rowdy?.process_notices_count
                                        }}</h3>
                                    <label class="card-title fw-semibold mb-2">Notices</label>
                                </div>
                                <div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#processModal"
                                    @click="getProcesses(display_gang.leader.rowdy_id, 'warrant')">
                                    <h3 class="mb-0 text-center">{{ display_gang.leader?.rowdy?.process_warrants_count
                                        }}</h3>
                                    <label class="card-title fw-semibold mb-2">Warrants</label>
                                </div>
                                <div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#processModal"
                                    @click="getProcesses(display_gang.leader.rowdy_id, 'summons')">
                                    <h3 class="mb-0 text-center">{{ display_gang.leader?.rowdy?.process_summons_count
                                        }}</h3>
                                    <label class="card-title fw-semibold mb-2">Summons</label>
                                </div>
                                <div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#processModal"
                                    @click="getProcesses(display_gang.leader.rowdy_id, 'proclamation')">
                                    <h3 class="mb-0 text-center">{{
                                        display_gang.leader?.rowdy?.process_proclamation_count }}
                                    </h3>
                                    <label class="card-title fw-semibold mb-2">Proclamation</label>
                                </div>
                                <div class="col mt-2" data-bs-toggle="modal" data-bs-target="#processModal"
                                    @click="getProcesses(display_gang.leader.rowdy_id)">
                                    <h3 class="mb-0 text-center">{{ display_gang.leader?.rowdy?.process_count }}</h3>
                                    <label class="card-title fw-semibold mb-2">Total</label>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="goonda">
                            <div class="row mt-2 row-cols-auto g-3 g-xl-4 gap-2 pt-2 text-dark justify-content-center">
                                <div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#goondaModal"
                                    @click="getGoondas(display_gang.leader.rowdy_id, 'goonda')">
                                    <h3 class="mb-0 text-center">{{ display_gang.leader?.rowdy?.goonda_count }}</h3>
                                    <label class="card-title fw-semibold mb-2">Goonda Act</label>
                                </div>
                                <div class="col mt-2 border-right" data-bs-toggle="modal" data-bs-target="#goondaModal"
                                    @click="getGoondas(display_gang.leader.rowdy_id, 'externment')">
                                    <h3 class="mb-0 text-center">{{ display_gang.leader?.rowdy?.externment_count }}
                                    </h3>
                                    <label class="card-title fw-semibold mb-2">Externment</label>
                                </div>
                                <div class="col mt-2" data-bs-toggle="modal" data-bs-target="#goondaModal"
                                    @click="getGoondas(display_gang.leader.rowdy_id)">
                                    <h3 class="mb-0 text-center">{{ display_gang.leader?.rowdy?.goonda_externment_count
                                        }}</h3>
                                    <label class="card-title fw-semibold mb-2">Total</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-xl-12 my-4 ">
            <div class="border-design border-bottom-0 mb-1">
                <p class="text-center">
                    <span class="badge rounded-pill bg-light-white rounded text-dark fs-14 fw-500 text-center">{{
                        display_gang.associates?.length }} Members</span>
                </p>
            </div>
            <div class="row gap-2 row-cols-1 row-cols-md-auto d-flex justify-content-between ">
                <div class="col mb-2" v-for="associate, key in display_gang.associates">
                    <div class="card card-people">
                        <div class="card-body">
                            <router-link :to="'/view_rowdy/' + associate.rowdy_id"><img class="multiple_img img-fluid"
                                    :src="associate.rowdy.rowdy_sheeter_photo" alt="" /></router-link>
                            <h6 class="mt-3 text-center"><router-link :to="'/view_rowdy/' + associate.rowdy_id">{{
                                associate.rowdy.rowdy_sheeter_name }}</router-link></h6>
                            <div class="product-review-footer mt-auto" style="justify-content: center;">
                                <!-- <a href="javascript:void(0)">{{ associate.rowdy.age }}</a>  -->
                                <a href="javascritp:void(0)" data-bs-toggle="modal" data-bs-target="#casesModal"
                                    @click="getCases(associate.rowdy.rowdy_id)"> {{ associate.rowdy.no_of_cases }}
                                    cases</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- cases modal -->
    <div class="modal fade bd-example-modal-lg" tabindex="-1" id="casesModal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">CASES</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table table-sm table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th class="text-center">SL No.</th>
                                    <th>Police Station</th>
                                    <th>Crime Number</th>
                                    <th>FIR Date</th>
                                    <th>Section of Law</th>
                                    <th>FIR Stage</th>
                                    <th>Case Details</th>
                                    <th>Court Name</th>
                                    <th>CC / SC Number</th>
                                    <th>Next Hearing Date</th>
                                    <th>Crime Head</th>
                                    <th>Accused Names</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="rowdy_case, key in cases">
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td>{{ rowdy_case.police_station?.police_station }}</td>
                                    <td>{{ rowdy_case.crime_number }}</td>
                                    <td>{{ rowdy_case.fir_date }}</td>
                                    <td>{{ rowdy_case.section_of_law }}</td>
                                    <td>{{ rowdy_case.fir_stage }}</td>
                                    <td>{{ rowdy_case.case_details }}</td>
                                    <td>{{ rowdy_case.court_name }}</td>
                                    <td>{{ rowdy_case.cc_sc_number }}</td>
                                    <td>{{ rowdy_case.next_hearing_date }}</td>
                                    <td>{{ rowdy_case.crime_head }}</td>
                                    <td>{{ rowdy_case.accused_names }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
            </div>
        </div>
    </div>
    <!-- end of cases modal -->

    <!-- process modal -->
    <div class="modal fade bd-example-modal-lg" tabindex="-1" id="processModal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Process</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table table-sm table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th class="text-center">SL No.</th>
                                    <th>Process Type</th>
                                    <th>Police Station</th>
                                    <th>Crime Number</th>
                                    <th>Section of Law</th>
                                    <th>Issued Date</th>
                                    <th>Served/Unserved</th>
                                    <th>Next Hearing Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="process, key in processes">
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td>{{ process.process_type }}</td>
                                    <td>{{ process.police_station?.police_station }}</td>
                                    <td>{{ process.crime_number }}</td>
                                    <td>{{ process.section_of_law }}</td>
                                    <td>{{ process.issued_date }}</td>
                                    <td>{{ process.status }}</td>
                                    <td>{{ process.next_hearing_date }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
            </div>
        </div>
    </div>
    <!-- end of process modal -->

    <!-- process modal -->
    <div class="modal fade bd-example-modal-lg" tabindex="-1" id="goondaModal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Goonda / Externment</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table table-sm table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th class="text-center">SL No.</th>
                                    <th>Type</th>
                                    <th>Details</th>
                                    <th>Police Station</th>
                                    <th>Person Name</th>
                                    <th>Address</th>
                                    <th>Activities</th>
                                    <th>Order No</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="goonda, key in goondas">
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td>{{ goonda.type }}</td>
                                    <td>{{ goonda.details }}</td>
                                    <td>{{ goonda.police_station.police_station }}</td>
                                    <td>{{ goonda.person_name }}</td>
                                    <td>{{ goonda.address }}</td>
                                    <td>{{ goonda.activities }}</td>
                                    <td>{{ goonda.order_no }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
            </div>
        </div>
    </div>
    <!-- end of process modal -->

    <!-- IPC modal -->
    <div class="modal fade bd-example-modal-lg" tabindex="-1" id="ipcModal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">IPC {{ ipc }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table table-sm table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th class="text-center">SL No.</th>
                                    <th>Police Station</th>
                                    <th>Crime No.</th>
                                    <th>FIR Date</th>
                                    <th>Section of Law</th>
                                    <th>FIR Stage</th>
                                    <th>Case Details</th>
                                    <th>Court Name</th>
                                    <th>CC/SC No</th>
                                    <th>Next Hearing Date</th>
                                    <th>Crime Head</th>
                                    <th>Accused Names</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="ipc_case, key in ipc_cases">
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td>{{ ipc_case.police_station.police_station }}</td>
                                    <td>{{ ipc_case.crime_number }}</td>
                                    <td>{{ ipc_case.fir_date }}</td>
                                    <td>{{ ipc_case.section_of_law }}</td>
                                    <td>{{ ipc_case.fir_stage }}</td>
                                    <td>{{ ipc_case.case_details }}</td>
                                    <td>{{ ipc_case.court_name }}</td>
                                    <td>{{ ipc_case.cc_sc_number }}</td>
                                    <td>{{ ipc_case.next_hearing_date }}</td>
                                    <td>{{ ipc_case.crime_head }}</td>
                                    <td>{{ ipc_case.accused_names }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
            </div>
        </div>
    </div>
    <!-- end of process modal -->

    <!-- image slide modal -->
    <div class="modal fade bd-example-modal-lg" tabindex="-1" id="imageSlideModal">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Gang Leader Images</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-2" style="display: flex; align-items: center;">
                            <button v-if="this.shift.index > 0" class="btn btn-sm btn-outline-primary float-start"
                                @click="shiftLeft()"> <i class="ri-arrow-left-line"></i> </button>
                        </div>
                        <div class="col-8">
                            <img class="modal-img" :src="shift.image" alt="" />
                        </div>
                        <div class="col-2" style="display: flex; align-items: center;">
                            <button v-if="shift.index <= rowdy_images.length - 2"
                                class="btn btn-sm btn-outline-primary float-end" @click="shiftRight()"> <i
                                    class="ri-arrow-right-line"></i> </button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            gang: {
                gang_name: ''
            },
            errors: [],
            cases: [],
            processes: [],
            goondas: [],
            ipc_cases: [],
            ipc: '',
            opposite_gangs: [],
            display_gang: {},
            opposite_gang_toggle: false,
            path: '',
            rowdy_images: [],
            shift: {
                index: 0,
                image: ''
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.getGang(to.params.gang_id);
            vm.path = from.path;
        })
    },

    methods: {
        getGang(gang_id) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getGang', data: { gang_id: gang_id } })
                .then(response => {
                    loader.hide();
                    this.gang = response.data.data;
                    this.display_gang = this.gang;
                    if (this.gang.leader) {
                        this.getRowdyImages();
                    }
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getOppositeGangs() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getOppositeGangs', data: { gang_id: this.gang.gang_id } })
                .then(response => {
                    loader.hide();
                    this.opposite_gangs = response.data.data;
                    if (this.opposite_gangs.length) {
                        this.setGang(this.opposite_gangs[0].gang_id);
                    }
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        setGang(gang_id) {
            if (this.opposite_gangs.length == 0) {
                this.opposite_gang_toggle = false;
                alert("No Opposite gang!");
                return;
            } else if (this.opposite_gang_toggle) {
                this.display_gang = this.opposite_gangs.find(ele => ele.gang_id == gang_id);
            } else {
                this.display_gang = this.gang;
            }
        },

        viewProfile(rowdy_id) {
            this.$router.push('/view_rowdy/' + rowdy_id);
        },

        getCases(rowdy_id) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getCases', data: { rowdy_id: rowdy_id } })
                .then(response => {
                    loader.hide();
                    this.cases = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getProcesses(rowdy_id, process_type) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getProcesses', data: { rowdy_id: rowdy_id, process_type: process_type } })
                .then(response => {
                    loader.hide();
                    this.processes = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getGoondas(rowdy_id, type) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getGoondas', data: { rowdy_id: rowdy_id, type: type } })
                .then(response => {
                    loader.hide();
                    this.goondas = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getIpcCases(rowdy_id, case_type) {
            let vm = this;
            this.ipc = case_type;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getIpcCases', data: { rowdy_id: rowdy_id, case_type: case_type } })
                .then(response => {
                    loader.hide();
                    this.ipc_cases = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getRowdyImages() {
            let vm = this;
            let loader = vm.$loading.show();
            this.$store.dispatch('post', { uri: 'getRowdyImages', data: { rowdy_id: this.display_gang.leader.rowdy.rowdy_id } })
                .then(response => {
                    loader.hide();
                    this.rowdy_images = response.data.data;
                    if (this.rowdy_images.length) {
                        this.shift.index = 0;
                        this.shift.image = this.rowdy_images[0].image;
                    }
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        shiftLeft() {
            if (this.rowdy_images.length) {
                this.shift.index--;
                this.shift.image = this.rowdy_images[this.shift.index].image;
            }
        },

        shiftRight() {
            if (this.rowdy_images.length) {
                this.shift.index++;
                this.shift.image = this.rowdy_images[this.shift.index].image;
            }
        }
    }
}
</script>
<style>
.modal-img {
    width: -webkit-fill-available;
}
</style>