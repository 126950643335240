<template>
    <div class="d-md-flex align-items-center justify-content-between mb-2 mt-2">
        <div>
            <h4 class="main-title mb-0">Sub Divisions</h4>
        </div>
    </div>
    <div class="row g-3 mb-2">
        <div class="col-xl-12" style="height: fit-content;">
            <form class="card card-one" @submit.prevent="submitForm()">
                <div class="card-header">
                    <h6 class="card-title" v-if="status">New Sub Division </h6>
                    <h6 class="card-title" v-else>Update Sub Division </h6>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-label">Division</label><span class="text-danger"> *</span>
                            <select class="form-select border-0 border-bottom" :class="{ 'is-invalid': errors.division_id }"
                                v-model="sub_division.division_id" ref="division_id">
                                <option value="">Select Division</option>
                                <option v-for="division, key in divisions" :key="key" :value="division.division_id">{{
                                    division.division }}</option>
                            </select>
                            <span v-if="errors.division_id" class="invalid-feedback">{{ errors.division_id[0] }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">Sub Division</label><span class="text-danger"> *</span>
                            <input type="text" class="form-control border-0 border-bottom" placeholder="Sub Division"
                                :class="{ 'is-invalid': errors.sub_division }" v-model="sub_division.sub_division" />
                            <span v-if="errors.sub_division" class="invalid-feedback">{{ errors.sub_division[0]
                                }}</span>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">E-Mail</label>
                            <!-- <span class="text-danger"> *</span> -->
                            <input type="email" class="form-control border-0 border-bottom" placeholder="Enter official email." :class="{'is-invalid': errors?.email}" v-model="sub_division.email" ref="email" />
                            <span v-if="errors?.email" class="invalid-feedback">{{ errors?.email[0] }}</span>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="form-label">Address</label>
                            <!-- <span class="text-danger"> *</span> -->
                            <textarea class="form-control border-0 border-bottom" cols="30" rows="2" placeholder="Enter sub division address." :class="{'is-invalid': errors?.email}" v-model="sub_division.address"></textarea>                            
                            <span v-if="errors?.email" class="invalid-feedback">{{ errors?.address[0] }}</span>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-end">
                    <button class="btn btn-danger me-2" type="button" @click="discard()"><i
                            class="ri-prohibited-line me-1"></i>Discard</button>
                    <button class="btn btn-primary " type="submit">
                        <span v-if="status"> <i class="ri-save-line me-1"></i> Submit</span>
                        <span v-else> <i class="ri-save-line me-1"></i> Update </span>
                    </button>
                </div>
            </form>
        </div>
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-header">
                    <h6 class="card-title">Sub Divisions</h6>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <input type="text" class="form-control mb-2" id="search" placeholder="Search"
                            v-model="meta.search" @keypress.enter="search()">
                    </div>
                    <div class="table-responsive" style="overflow-y:scroll; height: 60vh;">
                        <table class="table text-nowrap table-bordered table-hover table-bottom-border mb-0">
                            <thead>
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center">Sl No.</th>
                                    <th @click="sort('division_id')">
                                        Division
                                        <span>
                                            <i v-if="meta.keyword == 'division_id' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'division_id' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th @click="sort('sub_division')">
                                        Sub Division
                                        <span>
                                            <i v-if="meta.keyword == 'sub_division' && meta.order_by == 'asc'"
                                                class="ri-arrow-up-line"></i>
                                            <i v-else-if="meta.keyword == 'sub_division' && meta.order_by == 'desc'"
                                                class="ri-arrow-down-line"></i>
                                            <i v-else class="fas fa-sort"></i>
                                        </span>
                                    </th>
                                    <th class="text-center">E-Mail</th>
                                    <th class="text-center">Address</th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="sub_division, key in sub_divisions" :key="key">
                                    <td class="text-center">{{ meta.from + key }}</td>
                                    <td>{{ sub_division.division?.division }}</td>
                                    <td>{{ sub_division.sub_division }}</td>
                                    <td>{{ sub_division.email }}</td>
                                    <td>{{ sub_division.address }}</td>
                                    <td class="text-center">
                                        <div class="form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch"
                                                :id="'sub_division' + sub_division.sub_division_id"
                                                :checked="sub_division.status" :value="sub_division.status"
                                                @change="deleteSubDivision(sub_division)" />
                                            <label class="custom-control-label"
                                                :for="'sub_division' + sub_division.sub_division_id"></label>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <nav>
                                            <a href="javascript:void(0)" class="text-primary me-2"
                                                @click="edit(sub_division)"><i class="ri-edit-line fs-18 lh-1"></i></a>
                                        </nav>
                                    </td>
                                </tr>
                                <tr v-if="sub_divisions.length <= 0">
                                    <td colspan="4" style="text-align: center;">No Records Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                        @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            meta: {
                search: "",
                order_by: "asc",
                keyword: "sub_division",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
            },
            status: true,
            sub_division: {
                sub_division_id: "",
                division_id: "",
                sub_division: "",
                email: '',
                address: '',
                status: ""
            },
            sub_divisions: [],
            divisions: [],
            errors: [],
        };
    },
    mounted() {
        let vm = this;
        vm.$refs.division_id.focus();
        vm.index();
        vm.getDivisions();
    },

    methods: {
        submitForm() {
            let vm = this;
            if (vm.status) {
                vm.create();
            } else {
                vm.update();
            }
        },
        index() {
            let vm = this;
            vm.$store.dispatch("post", { uri: "paginateSubDivisions", data: vm.meta })
                .then(function (response) {
                    vm.sub_divisions = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        create() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "addSubDivision", data: vm.sub_division };
            vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Sub Division is successfully created");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        edit(sub_division) {
            let vm = this;
            vm.sub_division = sub_division;
            vm.status = false;
            vm.$refs.division_id.focus();
        },
        update() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "updateSubDivision", data: vm.sub_division };
            vm.$store
                .dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Sub Division is successfully updated");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        deleteSubDivision(sub_division) {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "deleteSubDivision", data: sub_division };
            vm.$store
                .dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", response.data.message);
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getDivisions() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getDivisions" };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.divisions = response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        discard() {
            let vm = this;
            vm.sub_division.sub_division = "";
            vm.sub_division.division_id = "";
            vm.sub_division.sub_division_id = "";
            vm.errors = [];
            vm.status = true;
            vm.$refs.division_id.focus();
            vm.index();
        },
        onPageChange(page) {
            this.meta.page = page;
            this.index();
        },
        search() {
            let vm = this;
            vm.meta.page = 1;
            vm.index();
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },
    },
};
</script>
<style scoped>
input:focus,
select:focus {
    outline: none;
    box-shadow: 0 0 0 0 transparent;
}
</style>